import React, { useEffect, useRef, useState } from 'react'
import './Retanmedi.css'
import cash from "../../imgs/cash.png";
import back from "../../imgs/backexam.png";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';
import date from "../../imgs/bdate.png";



const Retanmedi = (props) => {
    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), kno: "", name: "", type: "",etype:"",amt: "", remark: "", tamt: "" });
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [dates, setDates] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10) });
    const [khnames, setKhnames] = useState([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');
    const refprint2 = useRef(null);
    const refprint2close = useRef(null);
    const [srdelete, setSrdelete] = useState('');
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const [wait, setWait] = useState(false);
    const today = new Date().toISOString().split('T')[0];


    function handleEnterKey(event, currentIndex) {

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).replace(/ /g, '-');  // Replacing spaces with hyphens
    };


    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date') {
            // If the value is blank or invalid, reset to the last valid date or today
            if (value === "" || value === "dd/mm/yyyy" || value > today) {
                setInput(prevInput => ({
                    ...prevInput,
                    date: prevInput.date && prevInput.date <= today ? prevInput.date : today
                }));
                return;
            }
        }

    }

    useEffect(() => {

        if (input.kno === "") {
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                type: "",
                amt: "",
                remark: "",

            }))

            setNewedit("new");
        }
        else {
            // getcustdata(input.kno);
            let custlisttemp = JSON.parse(localStorage.getItem("custlist"));
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.kno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname
                    }));
                    break; // Exit the loop once the condition is met
                }
            }
        }
    }, [input.kno])

    async function getcustdata(kno) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                response.data.map((user, index) =>
                (
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname,
                    }))
                )
                )
            }
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            saveuchal();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[4]) {
                    inputRefs.current[4].focus();
                }
            }
        }
    }

    const saveuchal = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.date === "" || input.kno === "" || input.name === "" || (input.type === "प्रकार निवडा" || input.type === "") || input.amt === "" || input.remark === "" || input.etype === "प्रकार निवडा") {
            notifyerror("अपूर्ण एंट्री माहिती!");
            setWait(false);
            return;
        }
        let rawData = {
            date: input.date,
            date1: dates.date1,
            date2: dates.date2,
            kno: input.kno,
            type: input.type,
            etype: input.etype,
            amt: input.amt,
            remark: input.remark,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit,
            sredit: sredit,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/saveretanmedi.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);
            if (newedit === "new") {
                if (response.data.trim() === "Saved.") {
                    notify("एंट्री सेव्ह झाली!");
                    setWait(false);
                }
            }
            else {
                if (response.data.trim() === "updated.") {
                    notify("एंट्री अपडेट झाली!")
                    setWait(false);
                }
            }
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                type: "",
                etype: "",
                amt: "",
                remark: "",
            }))
            setSredit("");
            setNewedit("new");
            getuchal(dates.date1, dates.date2);
            if (inputRefs.current[1]) {
                inputRefs.current[1].focus();
            }

        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    useEffect(() => {
        const currentDate1 = new Date(dates.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        if (localStorage.getItem("dur") === '10') {
            if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
                lastDayOfMonth1 = 10;
            } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
                lastDayOfMonth1 = 20;
            } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 31) {
                lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
            }
        }
        else if (localStorage.getItem("dur") === '15') {
            if (dayOfMonth1 >= 1 && dayOfMonth1 <= 15) {
                lastDayOfMonth1 = 15;
            } else if (dayOfMonth1 >= 16 && dayOfMonth1 <= 31) {
                lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
            }
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setDates(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));
        }
    }, [dates.date1]);


    useEffect(() => {
        const currentDate1 = new Date(input.date);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1,firstday;

        if (localStorage.getItem("dur") === '10') {
            if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
                firstday = "1";
                lastDayOfMonth1 = 10;
            } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
                firstday = "11";
                lastDayOfMonth1 = 20;
            } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
                firstday = "21";
                lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
            }
        }
        else if (localStorage.getItem("dur") === '15') {
            if (dayOfMonth1 >= 1 && dayOfMonth1 <= 15) {
                firstday = "1";
                lastDayOfMonth1 = 15;
            } else if (dayOfMonth1 >= 16 && dayOfMonth1 <= 30) {
                firstday = "16";
                lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
            }
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate1 = new Date(currentYear1, currentMonth1, firstday).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setDates(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
            //getuchal(formattedDate1, formattedDate2);
        }
    }, [input.date]);

    useEffect(() => {

        if (inputRefs.current[1]) {
            inputRefs.current[1].focus();
        }

        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let firstDayOfMonth;
        let lastDayOfMonth;

        if (localStorage.getItem("dur") === '10') {
            if (dayOfMonth >= 1 && dayOfMonth <= 10) {
                firstDayOfMonth = 1;
                lastDayOfMonth = 10;
            } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
                firstDayOfMonth = 11;
                lastDayOfMonth = 20;
            } else if (dayOfMonth >= 21 && dayOfMonth <= 31) {
                firstDayOfMonth = 21;
                lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
            }
        }
        else if (localStorage.getItem("dur") === '15') {
            if (dayOfMonth >= 1 && dayOfMonth <= 15) {
                firstDayOfMonth = 1;
                lastDayOfMonth = 10;
            } else if (dayOfMonth >= 16 && dayOfMonth <= 31) {
                firstDayOfMonth = 16;
                lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
            }
        }
        
        if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
            const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
            setDates(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
            getuchal(formattedDate1, formattedDate2);
        }
    }, [])


    const setNewPeriod = async (event) => {
        getuchal(dates.date1, dates.date2)
    }

    const handleChangedates = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setDates(value => ({ ...value, [name]: valuecurrent }));
    }


    async function getuchal(date1, date2) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getretanmedidate.php', { date1: date1, date2: date2, date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            //   console.log(response.data);
            if (response.data.length > 0) {
                if (response.data) {
                    setDataSource(response.data);
                    setInput(value => ({ ...value, tamt: response.data[0].tamt }));
                    setTimeout(() => {
                        props.setProgress(100);
                    }, 200);
                    handleprintclose();
                }
            }
            else {
                setDataSource([]);
                setInput(value => ({ ...value, tamt: "0" }));
                handleprintclose();
            }

        }).catch(err => {
            console.log(err);
            handleprintclose();
        });
    }

    const handleedit = (sr, date, kno, type,etype,amt, remark) => {

        setInput(prevInput => ({
            ...prevInput,
            sr: sr, date: date, kno: kno, type: type,etype:etype,amt: amt, remark: remark
        }))

        setNewedit("edit");
        setSredit(sr);
    }

    async function deleteuchal(sr) {
       
        handleDeleteclose();
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deleteuchal.php', { sr: srdelete, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                notify(response.data);
                setInput(prevInput => ({
                    ...prevInput,
                    kno: "",
                    name: "",
                    type: "",
                    amt: "",
                }))
                setSredit("");
                setNewedit("new");
                setSrdelete("");
                getuchal(dates.date1, dates.date2);
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }

            }

        }).catch(err => {
            console.log(err);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };


    const handleprintclose = () => {
        refprint2close.current.click();
    }

    const handlePrint = () => {

        refprint2.current.click();
    }

    const handleDeleteclose = () => {
        setSrdelete("");
        refdeleteclose.current.click();
        
    }

    const handleDelete = (sr) => {
        setSrdelete(sr);
        refdelete.current.click();
    }


    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint2}
                data-bs-target="#uchalperiod"
            >
            </button>

            <div className="modal fade" id="uchalperiod" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprint2close} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">कालावधी निवडा</h5>

                            <div className="rowbill">
                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={dates.date1} onChange={handleChangedates} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={dates.date2} onChange={handleChangedates} ></input>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={setNewPeriod} >ओके</button>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refdelete}
                data-bs-target="#deleteuchal"
            >
            </button>

            <div className="modal fade" id="deleteuchal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">एड्व्हांस एंट्री डिलीट करायची?</h5>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDeleteclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={deleteuchal} >होय</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="khadyamain">
                <div className="firstrowkhadya">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmainkhadya">
                        <div className="firstrow2">
                            <div className="row1kh100">
                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                                </div>
                                <div onClick={handlePrint} className="rightkhsaletop">
                                    <img className='khadyaicon' src={date} alt="" />
                                    <span className='khsaleblack'>कालावधी :<span className='khsaleblue'>{formatDate(dates.date1) + " ते " + formatDate(dates.date2)}</span> </span>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='linekh' />
                <div className="row1uchal">
                    <div className="mb-2">
                        <span className='subheadsank'>खाते क्रमांक</span>
                        <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2">
                        <span className='subheadsank'>सभासदाचे नाव</span>
                        <input type="text" disabled className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                    </div>
                </div>
                <div className='linekh' />
                <div className="row1uchal">
                    <div className="mb-2">
                        <span className='subheadsank'>प्रकार</span>
                        <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} value={input.type} name='type' id='type' onChange={handleChange} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} >
                            <option defaultValue="Select Grade">प्रकार निवडा</option>
                            <option value="नावे">नावे</option>
                            <option value="जमा">जमा</option>
                        </select>
                    </div>
                    <div className="mb-2">
                        <span className='subheadsank'>एंट्री प्रकार</span>
                        <select onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} value={input.etype} name='etype' id='etype' onChange={handleChange} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} >
                            <option defaultValue="Select Grade">प्रकार निवडा</option>
                            <option value="कृ.रेतन">कृ.रेतन</option>
                            <option value="पशुवैद्यकीय">पशुवैद्यकीय</option>
                        </select>
                    </div>

                    <div className="mb-2">
                        <span className='subheadsank'>रक्कम</span>
                        <input onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="amt" name="amt" value={input.amt} onChange={handleChange} ></input>
                    </div>

                    <div className="mb-2">
                        <span className='subheadsank'>रिमार्क</span>
                        <input type="text" onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="remark" name="remark" value={input.remark} onChange={handleChange} ></input>
                    </div>

                </div>
                <div className="row1uchal2">
                    <div className="mb-2">
                        <div className="row1uchal2">
                            <div className="row1khsale">
                                {
                                    wait ?
                                        <button type="submit" className="btn btn-primary mx-1 sanksmall">
                                            {
                                                "लोडिंग.."
                                            }
                                        </button>
                                        :
                                        <button onClick={saveuchal} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[6] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
                                            {newedit === "new" ?
                                                "सेव्ह" :
                                                "अपडेट"
                                            }
                                        </button>
                                }
                                <button type="submit" className="btn btn-primary mx-1 sanksmall">विक्री यादी</button>
                            </div>
                            <div className="rightkhsale">
                                <img className='khadyaicon' src={cash} alt="" />
                                <span className='uchalblack'>रक्कम : <span className='khsalered'>₹{input.tamt}</span> </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="firstrow2">
                    <div class="table-containeruchal">
                        <table id="myTable" className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className='thsmall' scope="col">क्र.</th>
                                    <th className='thsmall' scope="col">दिनांक</th>
                                    <th className='thsmall' scope="col">खाते क्र</th>
                                    <th className='thsmall' scope="col">नाव</th>
                                    <th className='thlarge' scope="col">प्रकार</th>
                                    <th className='thlarge' scope="col">ए.प्रकार</th>
                                    <th className='thsmall' scope="col">रक्कम</th>
                                    <th className='thsmall' scope="col">एक्शन</th>
                                </tr>
                            </thead>
                            <tbody>
                                {value.length > 0 ? tableFilter.map((user, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{user.date}</td>
                                        <td>{user.kno}</td>
                                        <td>{user.name}</td>
                                        <td>{user.type}</td>
                                        <td>{user.amt}</td>
                                        {/* <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr) }} v className="icsank" /></td> */}
                                    </tr>
                                )
                                    :
                                    DataSource.map((user, index) =>
                                        <tr key={index}>
                                            <td className='thsmallaudit'>{index + 1}</td>
                                            <td className='thsmallaudit'>{user.date}</td>
                                            <td className='thsmallaudit'>{user.kno}</td>
                                            <td className='thsmallaudit'>{user.name}</td>
                                            <td className='thsmallaudit'>{user.type}</td>
                                            <td className='thsmallaudit'>{user.etype}</td>
                                            <td className='thsmallaudit'>{user.amt}</td>
                                            <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.type,user.etype,user.amt, user.remark) }} className="icsank" /></td>
                                            {/* <UilTrash onClick={() => { handleDelete(user.sr) }} v className="icsank" /> */}
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default Retanmedi

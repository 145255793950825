import React, { useContext, useEffect, useRef, useState } from 'react'
import './Sanklan.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import buflogo from "../../imgs/buffelowlogo.png";
import cowlogo from "../../imgs/cowlogo.png";
import refresh from "../../imgs/refresh.png";
import close from "../../imgs/close.png";
import { Face6TwoTone, FaxTwoTone, Preview } from '@mui/icons-material';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';
import { compareSync } from 'bcryptjs';

const Sanklan = (props) => {

  const fatInputRef = useRef(null);
  const snfInputRef = useRef(null);

  const [newedit, setNewedit] = useState("new");
  const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), time: "", kno: "", name: "", vibhag: "", type: "", liter: "", fat: "", digri: "", snf: "", rate: "", amt: "", vibh1: "", vibh2: "", vibh3: "", vibh4: "", vibh5: "", vibh6: "", vibh7: "", vibh8: "", vibh1gay: "", vibh2gay: "", vibh3gay: "", vibh4gay: "", vibh5gay: "", vibh6gay: "", vibh7gay: "", vibh8gay: "", milkonoff: "" });
  const inputRefs = useRef([]);
  const [tcust, setTcust] = useState({ tcust: "", tcompleted: "", tliter: 0, tavg: 0, tsnfavg: 0, tmh: 0, mhavg: 0, mhsnfavg: 0, tgay: 0, gayavg: 0, gaysnfavg: 0 });
  const [sredit, setSredit] = useState('');
  const [DataSource, setDataSource] = useState([]);
  const [Prevdata, setPrevdata] = useState([]);
  const [Prev, setPrev] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [value, setValue] = useState('');
  const [dates, setDates] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10) });
  const [custdata, setCustdata] = useState([]);
  const { submenu, setsubmenu, newcustsource, setNewcustsource, custlist, setCustlist } = useContext(Globalcontext);
  const [tableFilter2, setTableFilter2] = useState([]);
  const [sendMsg, setSendmsg] = useState(true);
  const [custSank, SetCustsank] = useState(false);

  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  const refdelete = useRef(null);
  const refdeleteclose = useRef(null);
  const [flag, setFlag] = useState("");
  const [fatcol, setFatcol] = useState(true)

  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const today = new Date().toISOString().split('T')[0]; // gets current date in 'YYYY-MM-DD' format


  const handleClick = (flag) => {
    setFlag(flag);
    setExpanded(true);

  };

  useEffect(() => {
    if (expanded === true) {
      if (flag === "total") {
        getcustlist(flag);
      }
    }
  }, [expanded])

  async function getcustlist(flag) {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getcustlisttemp2.php', { flag: flag, date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data.length > 0) {
        setCustdata(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }


  async function getoldnextrates() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getoldnextrates.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data && response.data.length > 0) {
        response.data.map((user, index) =>
          setInput(prevInput => ({
            ...prevInput,
            vibh1: user.vibh1,
            vibh2: user.vibh2,
            vibh3: user.vibh3,
            vibh4: user.vibh4,
            vibh5: user.vibh5,
            vibh6: user.vibh6,
            vibh7: user.vibh7,
            vibh8: user.vibh8,
            vibh1gay: user.vibh1gay,
            vibh2gay: user.vibh2gay,
            vibh3gay: user.vibh3gay,
            vibh4gay: user.vibh4gay,
            vibh5gay: user.vibh5gay,
            vibh6gay: user.vibh6gay,
            vibh7gay: user.vibh7gay,
            vibh8gay: user.vibh8gay
          }))
        )

        const currentHour = new Date().getHours();

        // Set time based on current hour
        const timeValue = currentHour < 15 ? "सकाळ" : "संध्याकाळ";
        console.log(timeValue);
        // Update the state
        setInput(prevInput => ({
          ...prevInput,
          time: timeValue,
        }));


      }
    }).catch(err => {
      console.log(err);
    });
  }



  const handleedit = (sr, date, time, kno, name, vibhag, type, liter, fat, snf, rate, amt, digri) => {
    setNewedit("edit");

    //setInput({ date: date, time: time, kno: kno, name: name, vibhag: vibhag, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })

    setInput(prevInput => ({
      ...prevInput,
      date: date, time: time, kno: kno, name: name, vibhag: vibhag, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt, digri: digri
    }));

    setTimeout(() => {
      //setInput({ date: date, time: time, kno: kno, name: name, vibhag: vibhag, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })

      setInput(prevInput => ({
        ...prevInput,
        date: date, time: time, kno: kno, name: name, vibhag: vibhag, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt, digri: digri
      }));

      setSredit(sr);

      if (inputRefs.current[4]) {
        inputRefs.current[4].focus();
      }
    }, 0);

  }

  // useEffect(() => {
  //   console.log(input);
  // }, [input])

  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }));

    if (name === 'date') {
      // If the value is blank or invalid, reset to the last valid date or today
      if (value === "" || value === "dd/mm/yyyy" || value > today) {
        setInput(prevInput => ({
          ...prevInput,
          date: prevInput.date && prevInput.date <= today ? prevInput.date : today
        }));
        return;
      }
    }
    if (valuecurrent.includes('.')) {
      const parts = valuecurrent.split('.');
      if (parts[1].length === 1) {
        if (name === 'liter') {
          inputRefs.current[5].focus();
        }
      }
    }
  }

  const handleChangefs = async e => {
    const { name, value } = e.target;
    let valuecurrent = "";
    valuecurrent = e.target.value;
    if (value === "") {
      setInput(prevInput => ({
        ...prevInput,
        [name]: value
      }));
    } else if (/^\d*(\.\d{0,1})?$/.test(value)) {
      setInput(prevInput => ({
        ...prevInput,
        [name]: value
      }));

      if (valuecurrent.includes('.')) {
        const parts = valuecurrent.split('.');
        if (parts[1].length === 1) {
          if (name === 'fat') {
            setTimeout(() => {
              inputRefs.current[6].focus();
            }, 0);
          }
          else if (name === 'snf') {
            setTimeout(() => {
              inputRefs.current[7].focus();
            }, 0);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("sankdate") && localStorage.getItem("sanktime")) {
      setInput(prevInput => ({
        ...prevInput,
        date: localStorage.getItem("sankdate"),
        time: localStorage.getItem("sanktime")
      }))

      localStorage.setItem("sankdate", "");
      localStorage.setItem("sanktime", "")
    }
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }

    if (inputRefs.current[1]) {
      inputRefs.current[1].focus();
    }

    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let firstDayOfMonth;
    let lastDayOfMonth;

    if (localStorage.getItem("dur") === '10') {
      if (dayOfMonth >= 1 && dayOfMonth <= 10) {
        firstDayOfMonth = 1;
        lastDayOfMonth = 10;
      } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
        firstDayOfMonth = 11;
        lastDayOfMonth = 20;
      } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
        firstDayOfMonth = 21;
        lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
      }
    }
    else if (localStorage.getItem("dur") === '15') {
      if (dayOfMonth >= 1 && dayOfMonth <= 15) {
        firstDayOfMonth = 1;
        lastDayOfMonth = 10;
      } else if (dayOfMonth >= 16 && dayOfMonth <= 30) {
        firstDayOfMonth = 16;
        lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
      }
    }

    if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
      const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
      const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
      setDates(prevInput => ({
        ...prevInput,
        date1: formattedDate1,
        date2: formattedDate2
      }));
    }

    getoldnextrates();



  }, [])
  useEffect(() => {
    if (input.kno === "") {
      if (custSank) {
        setDataSource([]);
      }
      setInput(prevInput => ({
        ...prevInput,
        kno: "",
        name: "",
        vibhag: "",
        type: "",
        liter: "",
        fat: "",
        snf: "",
        rate: "",
        amt: ""
      }))
      setSredit("");
      setNewedit("new");
    }
    else {
      if (newedit === 'new') {
        let custlisttemp = JSON.parse(localStorage.getItem("custlist"));

        for (let i = 0; i < custlisttemp.length; i++) {
          setInput(prevInput => ({
            ...prevInput,
            name: ''
          }));
          const user = custlisttemp[i];
          if (user.kno === input.kno) {
            setInput(prevInput => ({
              ...prevInput,
              name: user.cname,
              type: user.type,
              vibhag: user.vibhag,
              milkonoff: user.milkonoff
            }));
            break; // Exit the loop once the condition is met
          }
        }
      }
    }
  }, [input.kno])

  useEffect(() => {
    if (newedit === "new") {
      setInput(prevInput => ({
        ...prevInput,
        rate: "",
        amt: "",
      }))
    }

    if (input.fat !== "" && input.digri !== "") {
      // Convert input.fat and input.digri to numbers
      let fatValue = parseFloat(input.fat);
      let digriValue = parseFloat(input.digri);

      if (!isNaN(fatValue) && !isNaN(digriValue)) {
        let snftemp = ((((fatValue * 0.21) + 0.36) * 4) + digriValue) / 4;

        setInput(prevInput => ({
          ...prevInput,
          snf: parseFloat(snftemp.toFixed(1)) // Limit to 1 decimal point
        }));
      } else {
        console.error("Invalid input values for fat or digri");
      }
    }

    if (input.fat !== "" && input.fat !== "" && input.type !== "") {
      // getrate(input.fat, input.snf, input.type);

      getratefromdata();
    }
  }, [input.fat])

  useEffect(() => {
    if (newedit === "new") {
      setInput(prevInput => ({
        ...prevInput,
        snf: "",
        rate: "",
        amt: "",
      }))
    }
    if (input.fat !== "" && input.digri !== "") {
      // Convert input.fat and input.digri to numbers
      let fatValue = parseFloat(input.fat);
      let digriValue = parseFloat(input.digri);

      if (!isNaN(fatValue) && !isNaN(digriValue)) {
        let snftemp = ((((fatValue * 0.21) + 0.36) * 4) + digriValue) / 4;
        setInput(prevInput => ({
          ...prevInput,
          snf: parseFloat(snftemp.toFixed(1)) // Limit to 1 decimal point
        }));
      } else {
        console.error("Invalid input values for fat or digri");
      }
    }

  }, [input.digri])

  useEffect(() => {
    if (newedit === "new") {
      setInput(prevInput => ({
        ...prevInput,
        rate: "",
        amt: "",
      }))
    }
    if (input.fat !== "" && input.fat !== "" && input.type !== "") {
      // getrate(input.fat, input.snf, input.type);

      getratefromdata();

    }

  }, [input.snf])

  const getratefromdata = () => {

    let rates = null;
    let rates9 = null;
    if (input.type === "म्हैस") {
      rates = JSON.parse(localStorage.getItem("mhrates"));
      if (localStorage.getItem("mhrates9") !== "") {
        rates9 = JSON.parse(localStorage.getItem("mhrates9"));
      }
    }
    else if (input.type === "गाय") {
      rates = JSON.parse(localStorage.getItem("gayrates"));
      if (localStorage.getItem("gayrates9") !== "") {
        rates9 = JSON.parse(localStorage.getItem("gayrates9"));
      }
    }

    if (!rates) {
      notifyerror("कृपया दूध प्रकार निवडा!")
      return;
    }

    if (input.vibhag !== "दरपत्रक विभाग-९ (M)") {
      for (let i = 0; i < rates.length; i++) {
        const user = rates[i];

        let diff = 0;

        if (input.vibhag === "दरपत्रक विभाग-१" && input.type === 'म्हैस') {
          diff = input.vibh1;
        }
        else if (input.vibhag === "दरपत्रक विभाग-१" && input.type === 'गाय') {
          diff = input.vibh1gay;
        }
        else if (input.vibhag === "दरपत्रक विभाग-२" && input.type === 'म्हैस') {
          diff = input.vibh2;
        }
        else if (input.vibhag === "दरपत्रक विभाग-२" && input.type === 'गाय') {
          diff = input.vibh2gay;
        }
        else if (input.vibhag === "दरपत्रक विभाग-३" && input.type === 'म्हैस') {
          diff = input.vibh3;
        }
        else if (input.vibhag === "दरपत्रक विभाग-३" && input.type === 'गाय') {
          diff = input.vibh3gay;
        }
        else if (input.vibhag === "दरपत्रक विभाग-४" && input.type === 'म्हैस') {
          diff = input.vibh4;
        }
        else if (input.vibhag === "दरपत्रक विभाग-४" && input.type === 'गाय') {
          diff = input.vibh4gay;
          console.log("Diff- ", input.vibh4gay);
        }
        else if (input.vibhag === "दरपत्रक विभाग-५" && input.type === 'म्हैस') {
          diff = input.vibh5;
        }
        else if (input.vibhag === "दरपत्रक विभाग-५" && input.type === 'गाय') {
          diff = input.vibh5gay;
        }
        else if (input.vibhag === "दरपत्रक विभाग-६" && input.type === 'म्हैस') {
          diff = input.vibh6;
        }
        else if (input.vibhag === "दरपत्रक विभाग-६" && input.type === 'गाय') {
          diff = input.vibh6gay;
        }
        else if (input.vibhag === "दरपत्रक विभाग-७" && input.type === 'म्हैस') {
          diff = input.vibh7;
        }
        else if (input.vibhag === "दरपत्रक विभाग-७" && input.type === 'गाय') {
          diff = input.vibh7gay;
        }
        else if (input.vibhag === "दरपत्रक विभाग-८" && input.type === 'म्हैस') {
          diff = input.vibh8;
        }
        else if (input.vibhag === "दरपत्रक विभाग-८" && input.type === 'गाय') {
          diff = input.vibh8gay;
        }



        if (user.fat === input.fat && user.snf === input.snf) {
          setTimeout(() => {
            setInput(prevInput => ({
              ...prevInput,
              rate: parseFloat(user.rate) + parseFloat(diff),
              amt: parseFloat(parseFloat(parseFloat(user.rate) + parseFloat(diff)) * parseFloat(input.liter)).toFixed(2)
            }))
          }, 0);
          break; // Exit the loop once the condition is met
        }

      }
    }
    else if (input.vibhag === "दरपत्रक विभाग-९ (M)") {
      for (let i = 0; i < rates9.length; i++) {
        const user = rates9[i];
        if (user.fat === input.fat && user.snf === input.snf) {
          setTimeout(() => {
            setInput(prevInput => ({
              ...prevInput,
              rate: user.rate,
              amt: parseFloat(parseFloat(user.rate) * parseFloat(input.liter)).toFixed(2)
            }))
          }, 0);
          break; // Exit the loop once the condition is met
        }
      }

    }
  }

  useEffect(() => {
    if (newedit === "new") {
      if (input.type !== "प्रकार निवडा") {
        setInput(prevInput => ({
          ...prevInput,
          rate: "",
          amt: "",
          type: input.type
        }))
      }
      if (input.fat !== "" && input.fat !== "" && input.type !== "" && newedit === "new") {
        //getrate(input.fat, input.snf, input.type);
        getratefromdata();
      }
    }
    setPrev([]);
  }, [input.type])

  useEffect(() => {
    if (input.liter === "") {
      setInput(prevInput => ({
        ...prevInput,
        amt: "0",
      }))
    }
    if (input.liter !== "" && input.rate !== "") {
      setInput(prevInput => ({
        ...prevInput,
        amt: parseFloat(parseFloat(input.rate) * parseFloat(input.liter)).toFixed(2)
      }))
    }
  }, [input.liter])

  useEffect(() => {
    setExpanded(false);
    if (input.time) {
      if (input.date) {
        //  if(!custSank)
        //   { 
        //     getsanklan();
        //   }
        //   else{
        //     getsanklanbykno()
        //   }
      }
    }
    else {
      setTcust({ tcust: "", tcompleted: "" });
    }
  }, [input.time])

  useEffect(() => {
    const selectedDate = new Date(input.date);
    // Get the current date
    const currentDate = new Date();

    // Check if the selected date is equal to the current date
    const isToday = selectedDate.toDateString() === currentDate.toDateString();

    // Set the sendMsg state based on whether it's today or not
    setSendmsg(isToday);

    setPrev([]);
    setInput(prevInput => ({
      ...prevInput,
      time: "वेळ निवडा"
    }))
    setTcust({ tcust: "", tcompleted: "", tliter: 0, tavg: 0, tmh: 0, mhavg: 0, tgay: 0, gayavg: 0 });

    const currentDate1 = new Date(input.date);
    const dayOfMonth1 = currentDate1.getDate();
    const currentMonth1 = currentDate1.getMonth();
    const currentYear1 = currentDate1.getFullYear();
    let firstedate = "";

    let lastDayOfMonth1;

    if (localStorage.getItem("dur") === '10') {
      if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
        firstedate = 1;
        lastDayOfMonth1 = 10;
      } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
        firstedate = 11;
        lastDayOfMonth1 = 20;
      } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
        firstedate = 21;
        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
      }
    }
    else if (localStorage.getItem("dur") === '15') {
      if (dayOfMonth1 >= 1 && dayOfMonth1 <= 15) {
        firstedate = 1;
        lastDayOfMonth1 = 15;
      } else if (dayOfMonth1 >= 16 && dayOfMonth1 <= 30) {
        firstedate = 16;
        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
      }
    }

    if (lastDayOfMonth1 !== undefined) {
      const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
      const formattedDate1 = new Date(currentYear1, currentMonth1, firstedate).toLocaleDateString('en-CA');
      setDates(prevInput => ({
        ...prevInput,
        date1: formattedDate1,
        date2: formattedDate2
      }));
    }
  }, [input.date])


  const getsanksanklandata = async () => {

    if (!custSank) {
      if (input.date && input.time) {
        getsanklan();
      }
    }
    else {
      if (input.date && input.kno) {
        getsanklanbykno();
      }
    }
  }

  const fetchData = async () => {

    try {
      const response = await fetch('https://idairy.co.in/dairy/php/cust/testing8.php');
      const data = await response.json();
      // console.log(data);
      //setQuantity(data.scaleReading);

    } catch (error) {
      console.error('Error fetching scale data:', error);
    }
  };


  async function getoldentry() {

    DataSource
      .filter(data => data.kno === input.kno && data.type === input.type && data.date === input.date && data.time === input.time)
      .map((user, index) => (
        handleedit(user.sr, user.date, user.time, user.kno, user.name, user.vibhag, user.type, user.liter, user.fat, user.snf, user.rate, user.amt)
      ));
    getprevfatsnf2()
  }



  async function checkonoff() {
    if (input.milkonoff === "बंद") {
      if (inputRefs.current[2]) {
        inputRefs.current[2].focus();
      }
      notifyerror(input.name + " यांचे दूध सध्या बंद आहे!");
      return;
    }
  }

  const getprevfatsnf2 = () => {
    // console.log(Prevdata);
    for (let i = 0; i < Prevdata.length; i++) {
      const user = Prevdata[i];
      if (user.kno === input.kno && user.type === input.type && user.time === input.time) {
        //  console.log("in");
        setPrev((prevInputSettings) => ({
          ...prevInputSettings,
          liter: user.liter,
          fat: user.fat,
          snf: user.snf,
        }));
        break; // Exit the loop once the condition is met
      }
    }
  }

  // useEffect(() => {
  //   console.log(Prev.liter)
  // }, [Prev])


  async function getsanklan() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getsanklanvibhag.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {
        setDataSource(response.data);
        setTimeout(() => {
          props.setProgress(100);
        }, 200);
        const totals = response.data.reduce(
          (accumulator, user) => {
            const liter = parseFloat(user.liter);
            const fat = parseFloat(user.fat);

            accumulator.totalLiter += liter;
            accumulator.tfat += fat;

            if (user.type === "म्हैस") {
              accumulator.totalLitermh += liter;
              accumulator.tfatmh += fat;
              accumulator.literfatmh += (liter * fat);
              accumulator.tentrymh += 1;
            } else if (user.type === "गाय") {
              accumulator.totalLitergay += liter;
              accumulator.tfatgay += fat;
              accumulator.literfatgay += (liter * fat);
              accumulator.tentrygay += 1;
            }
            return accumulator;
          },
          {
            totalLiter: 0,
            tfat: 0,
            totalLitermh: 0,
            tfatmh: 0,
            tentrymh: 0,
            totalLitergay: 0,
            tfatgay: 0,
            tentrygay: 0,
            literfatmh: 0,
            literfatgay: 0
          }
        );

        const {
          totalLiter,
          tfat,
          totalLitermh,
          tfatmh,
          tentrymh,
          tfatgay,
          tentrygay,
          totalLitergay,
          literfatmh,
          literfatgay
        } = totals;

        response.data.map((user, index) =>
        (
          setTcust(prevInput => ({
            ...prevInput,
            tcust: user.tcustomer,
            tliter: totalLiter.toFixed(2),
            tavg: parseFloat(parseFloat(tfat) / parseFloat(response.data.length)).toFixed(1),
            tmh: totalLitermh.toFixed(2),
            // mhavg: parseFloat(parseFloat(tfatmh) / parseFloat(tentrymh)).toFixed(1),
            mhavg: parseFloat(parseFloat(literfatmh) / parseFloat(totalLitermh)).toFixed(1),
            tgay: totalLitergay.toFixed(2),
            //gayavg: parseFloat(parseFloat(tfatgay) / parseFloat(tentrygay)).toFixed(1),
            gayavg: parseFloat(parseFloat(literfatgay) / parseFloat(totalLitergay)).toFixed(1),

          }))
        ))
        setTcust(prevInput => ({
          ...prevInput,
          tcompleted: response.data.length,
        }))
      }


      if (response.data.length == 0) {
        setTcust(prevInput => ({
          ...prevInput,
          tcust: 0,
          tcompleted: 0
        }))
      }

      getprevsanklan();

    }).catch(err => {
      console.log(err);
    });
  }

  async function getsanklanbykno() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getsanklanbykno.php', { date: input.date, kno: input.kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {
        setDataSource(response.data);
        setTimeout(() => {
          props.setProgress(100);
        }, 200);
        const totals = response.data.reduce(
          (accumulator, user) => {
            const liter = parseFloat(user.liter);
            const fat = parseFloat(user.fat);

            accumulator.totalLiter += liter;
            accumulator.tfat += fat;

            if (user.type === "म्हैस") {
              accumulator.totalLitermh += liter;
              accumulator.tfatmh += fat;

              accumulator.literfatmh += (liter * fat);
              accumulator.tentrymh += 1;
            } else if (user.type === "गाय") {
              accumulator.totalLitergay += liter;
              accumulator.tfatgay += fat;
              accumulator.tentrygay += 1;
            }
            return accumulator;
          },
          {
            totalLiter: 0,
            tfat: 0,
            totalLitermh: 0,
            tfatmh: 0,
            tentrymh: 0,
            totalLitergay: 0,
            tfatgay: 0,
            tentrygay: 0,
            literfatmh: 0
          }
        );

        const {
          totalLiter,
          tfat,
          totalLitermh,
          tfatmh,
          tentrymh,
          tfatgay,
          tentrygay,
          totalLitergay,
          literfatmh
        } = totals;

        response.data.map((user, index) =>
        (

          setTcust(prevInput => ({
            ...prevInput,
            tcust: user.tcustomer,
            tliter: totalLiter.toFixed(2),
            tavg: parseFloat(parseFloat(tfat) / parseFloat(response.data.length)).toFixed(1),
            tmh: totalLitermh.toFixed(2),
            // mhavg: parseFloat(parseFloat(tfatmh) / parseFloat(tentrymh)).toFixed(1),
            mhavg: 0,  //parseFloat(parseFloat(literfatmh) / parseFloat(totalLitermh)).toFixed(1),
            tgay: totalLitergay.toFixed(2),
            gayavg: parseFloat(parseFloat(tfatgay) / parseFloat(tentrygay)).toFixed(1),

          }))
        ))
        setTcust(prevInput => ({
          ...prevInput,
          tcompleted: response.data.length,
        }))
      }

      // if (response.data.length == 0) {
      //   setTcust(prevInput => ({
      //     ...prevInput,
      //     tcust: 0,
      //     tcompleted: 0
      //   }))
      // }

      //getprevsanklan();

    }).catch(err => {
      console.log(err);
    });
  }



  async function getprevsanklan() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getprevsanklan.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
      if (response.data) {
        setPrevdata(response.data);
        setTimeout(() => {
          props.setProgress(100);
        }, 200);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  function handleEnterKey(event, currentIndex) {
    //console.log(event.target.name + " " + currentIndex);
    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }


    if (event.key === 'Backspace' && (
      (event.target.tagName === 'INPUT' && event.target.value === '') ||
      (event.target.tagName === 'SELECT' && (
        (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
        (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
      ))
    )) {
      event.preventDefault();

      let prevIndex = currentIndex - 1;
      while (prevIndex >= 0) {
        const prevElement = inputRefs.current[prevIndex];
        if (prevElement) {
          if (
            (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
            prevElement !== event.target
          ) {
            prevElement.focus();
            break;
          } else {
            prevIndex--;
          }
        } else {
          prevIndex--;
        }
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  function handleEnterKeybutton(event, currentIndex) {
    if (event.key === 'Enter') {
      event.preventDefault();
      savesanklan();
      if (custSank) {
        if (inputRefs.current[1]) {
          inputRefs.current[1].focus();
        }
      }
      else {
        let nextIndex = null;
        if (currentIndex === 0) {
          if (input.name !== "") {
            nextIndex = currentIndex + 1;
          }
          else {
            nextIndex = currentIndex;
          }
        }
        else {
          nextIndex = currentIndex + 1;
        }
        if (inputRefs.current[nextIndex]) {
          inputRefs.current[nextIndex].focus();
        }
      }
    }
    else if (event.key === "Backspace") {
      if (event.target.tagName === "BUTTON") {
        if (inputRefs.current[6]) {
          inputRefs.current[6].focus();
        }
      }
    }
  }

  async function getcustdata(kno) {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    setTimeout(() => {
      props.setProgress(70);
    }, 200);
    axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data.length > 0) {
        response.data.map((user, index) =>
        (
          user.type !== "दोन्ही" ?
            setInput(prevInput => ({
              ...prevInput,
              name: user.cname,
              type: user.type
            }))
            :
            setInput(prevInput => ({
              ...prevInput,
              name: user.cname,
              type: "प्रकार निवडा"
            }))
        )
        )
      }
      setTimeout(() => {
        props.setProgress(100);
      }, 200);
    }).catch(err => {
      console.log(err);
    });
  }

  async function getratesdf(fat, snf, type) {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    let typeid = null;
    if (input.type === "म्हैस") {
      typeid = "1";
    }
    else {
      typeid = "2";
    }
    axios.post('https://idairy.co.in/dairy/php/cust/getrate.php', { typeid: typeid, fat: fat, snf: snf, type: type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
      //console.log(response.data);
      if (response.data) {
        setInput(prevInput => ({
          ...prevInput,
          rate: response.data,
          amt: parseFloat(parseFloat(response.data) * parseFloat(input.liter)).toFixed(2)
        }))
        // if (inputRefs.current[4]) {
        //   inputRefs.current[4].focus();
        // }
      }

    }).catch(err => {
      console.log(err);
    });
  }

  async function getprevfatsnf() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    let typeid = null;
    if (input.type === "म्हैस") {
      typeid = "1";
    }
    else {
      typeid = "2";
    }
    axios.post('https://idairy.co.in/dairy/php/cust/getprev.php', { kno: input.kno, typeid: typeid, type: input.type, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2, date: input.date }).then(function sample(response) {
      if (response.data) {
        //  console.log(response.data);
        setPrev(response.data);
      }


    }).catch(err => {
      console.log(err);
    });
  }

  async function deletesanklan(sr, date, time, kno, type) {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/deletesanklan2.php', { sr: sr, date: date, date1: dates.date1, date2: dates.date2, time: time, kno: kno, type: type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {
        notify(response.data);
        setInput(prevInput => ({
          ...prevInput,
          kno: "",
          name: "",
          type: "",
          liter: "",
          fat: "",
          snf: "",
          rate: "",
          amt: ""
        }))
        setSredit("");
        setNewedit("new");
        //getsanklan();
        removeentry(date, time, kno, type);
      }

    }).catch(err => {
      console.log(err);
    });
  }

  async function sendnotification(date, time, liter, fat, snf, rate, amt) {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    let rawData = {
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
      date: date,
      time: time,
      liter: liter,
      fat: fat,
      snf: snf,
      rate: rate,
      amt: amt,
      kendra: localStorage.getItem("kendra"),
      topic: localStorage.getItem("contact") + input.kno,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    if (newedit === "edit") {
      formData.append('data', rawData)
    }
    else if (newedit === "new") {
      formData.append('data', rawData)
    }

    axios.post('https://idairy.co.in/dairy/php/cust/sendnoti.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      notify(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  const savesanklan = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.date === "" || input.time === "वेळ निवडा" || input.time === "" || input.kno === "" || input.name === "" || input.type === "" || input.liter === "" || input.fat === "" || input.snf === "" || input.rate === "" || input.amt === "") {
      notifyerror("अपूर्ण एंट्री माहिती!");
      return;
    }
    let rawData = {
      date: input.date,
      date1: dates.date1,
      date2: dates.date2,
      time: input.time,
      kno: input.kno,
      vibhag: input.vibhag,
      type: input.type,
      liter: input.liter,
      fat: input.fat,
      digri: input.digri,
      snf: input.snf,
      rate: input.rate,
      amt: input.amt,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
      flag: newedit,
      sredit: sredit,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    if (newedit === "edit") {
      formData.append('data', rawData)
    }
    else if (newedit === "new") {
      formData.append('data', rawData)
    }
    axios.post('https://idairy.co.in/dairy/php/cust/savesanklanvibhag3.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      console.log(response.data);
      if (response.data.trim() === "Successfully saved.".trim()) {
        if (sendMsg === true) {
          //sendnotification(input.date, input.time, input.liter, input.fat, input.snf, input.rate, input.amt);
        }
        if (custSank) {
          notify("एंट्री सेव्ह झाली!");
          setNewedit("new");
          insertnewentry();
          setPrev([]);
          setInput(prevInput => ({
            ...prevInput,
            time: "",
            type: "",
            liter: "",
            digri: "",
            fat: "",
            snf: "",
            rate: "",
            amt: ""
          }))
        }
        else {
          notify("एंट्री सेव्ह झाली!");
          setSredit("");
          setNewedit("new");
          // getsanklan();
          insertnewentry();
          setPrev([]);
          setInput(prevInput => ({
            ...prevInput,
            kno: "",
            name: "",
            type: "",
            liter: "",
            fat: "",
            digri: "",
            snf: "",
            rate: "",
            amt: ""
          }))
        }
      }
      else if (response.data.trim() === "Successfully updated.".trim()) {
        notify("अपडेट झाले!")
        updateentry();
        setSredit("");
        setNewedit("new");
        setPrev([]);
        if (!custSank) {
          setInput(prevInput => ({
            ...prevInput,
            kno: "",
            name: "",
            type: "",
            liter: "",
            fat: "",
            digri: "",
            snf: "",
            rate: "",
            amt: ""
          }))
        }
        else {
          setInput(prevInput => ({
            ...prevInput,
            type: "",
            liter: "",
            fat: "",
            digri: "",
            snf: "",
            rate: "",
            amt: ""
          }))

          if (inputRefs.current[1]) {
            inputRefs.current[1].focus();
          }
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const insertnewentry = () => {
    // Create a new item object
    const newItem = {
      sr: "new", // Replace with the appropriate value
      date: input.date, // Replace with the appropriate value
      year_week: "-", // Replace with the appropriate value
      time: input.time, // Replace with the appropriate value
      kno: input.kno, // Replace with the appropriate value  
      vibhag: input.vibhag,
      type: input.type, // Replace with the appropriate value
      liter: input.liter, // Replace with the appropriate value
      fat: input.fat,
      digri: input.digri, // Replace with the appropriate value
      previous_fat: "Refresh", // Replace with the appropriate value
      differencefat: "Refresh", // Replace with the appropriate value
      snf: input.snf, // Replace with the appropriate value
      rate: input.rate, // Replace with the appropriate value
      amt: input.amt, // Replace with the appropriate value
      mainuser: localStorage.getItem("contact"),// Replace with the appropriate value
      name: input.name, // Replace with the appropriate value
      tcustomer: "0" // Replace with the appropriate value
    };

    // Update the state to include the new item
    //setDataSource((prevDataSource) => [...prevDataSource, newItem]);
    setDataSource((prevDataSource) => [newItem, ...prevDataSource]);
  }

  useEffect(() => {
    const totals = DataSource.reduce(
      (accumulator, user) => {
        const liter = parseFloat(user.liter);
        const fat = parseFloat(user.fat);
        const snf = parseFloat(user.snf);
        const amt = parseFloat(user.amt);

        accumulator.totalLiter += liter;
        accumulator.tfat += fat;
        accumulator.tsnf += snf;
        accumulator.tamt += amt;

        if (user.type === "म्हैस") {
          accumulator.totalLitermh += liter;
          accumulator.tfatmh += fat;
          accumulator.tsnfmh += snf;
          accumulator.tamtmh += amt;

          accumulator.literfatmh += (liter * fat);

          accumulator.tentrymh += 1;
        } else if (user.type === "गाय") {
          accumulator.totalLitergay += liter;
          accumulator.literfatgay += (liter * fat);
          accumulator.tfatgay += fat;
          accumulator.tsnfgay += snf;
          accumulator.tamtgay += amt;
          accumulator.tentrygay += 1;
        }
        return accumulator;
      },
      {
        totalLiter: 0,
        tfat: 0,
        totalLitermh: 0,
        tfatmh: 0,
        tentrymh: 0,
        totalLitergay: 0,
        tfatgay: 0,
        tentrygay: 0,
        tsnf: 0,
        tsnfmh: 0,
        tsnfgay: 0,
        tamt: 0,
        tamtmh: 0,
        tamtgay: 0,
        literfatmh: 0,
        literfatgay: 0
      }
    );

    const {
      totalLiter,
      tfat,
      totalLitermh,
      tfatmh,
      tentrymh,
      tfatgay,
      tentrygay,
      totalLitergay,
      tsnf,
      tsnfmh,
      tsnfgay,
      tamtmh,
      tamtgay,
      tamt,
      literfatmh,
      literfatgay
    } = totals;

    DataSource.map((user, index) =>
    (
      setTcust(prevInput => ({
        ...prevInput,
        tliter: totalLiter.toFixed(2),
        tavg: parseFloat(parseFloat(tfat) / parseFloat(DataSource.length)).toFixed(1),
        tsnfavg: parseFloat(parseFloat(tsnf) / parseFloat(DataSource.length)).toFixed(1),
        tmh: totalLitermh.toFixed(2),
        // mhavg: parseFloat(parseFloat(tfatmh) / parseFloat(tentrymh)).toFixed(1),
        mhavg: parseFloat(parseFloat(literfatmh) / parseFloat(totalLitermh)).toFixed(1),
        mhsnfavg: parseFloat(parseFloat(tsnfmh) / parseFloat(tentrymh)).toFixed(1),
        tgay: totalLitergay.toFixed(2),
        //gayavg: parseFloat(parseFloat(tfatgay) / parseFloat(tentrygay)).toFixed(1),
        gayavg: parseFloat(parseFloat(literfatgay) / parseFloat(totalLitergay)).toFixed(1),
        gaysnfavg: parseFloat(parseFloat(tsnfgay) / parseFloat(tentrygay)).toFixed(1),
        tamt: parseFloat(parseFloat(tamt)).toFixed(1),
        tamtmh: parseFloat(parseFloat(tamtmh)).toFixed(1),
        tamtgay: parseFloat(parseFloat(tamtgay)).toFixed(1),
      }))
    ))
    setTcust(prevInput => ({
      ...prevInput,
      tcompleted: DataSource.length,
    }))
  }, [DataSource])

  const updateentry = () => {
    // Create a new item object
    console.log("updated digri- ", input.digri);
    const modifiedItem = {
      date: input.date, // Replace with the appropriate value
      year_week: "-", // Replace with the appropriate value
      time: input.time, // Replace with the appropriate value
      kno: input.kno, // Replace with the appropriate value
      type: input.type, // Replace with the appropriate value
      liter: input.liter, // Replace with the appropriate value
      fat: input.fat, // Replace with the appropriate value
      digri: input.digri,
      snf: input.snf, // Replace with the appropriate value
      rate: input.rate, // Replace with the appropriate value
      amt: input.amt, // Replace with the appropriate value
      mainuser: localStorage.getItem("contact"),// Replace with the appropriate value
      name: input.name, // Replace with the appropriate value
      tcustomer: "0" // Replace with the appropriate value
    };

    if (!custSank) {
      const updatedDataSource = DataSource.map((item) => {
        if (item.kno === input.kno && item.type === input.type) {
          return { ...item, ...modifiedItem };
        } else {
          return item;
        }
      });

      setDataSource(updatedDataSource);
    }
    else {
      const updatedDataSource = DataSource.map((item) => {
        if (item.kno === input.kno && item.type === input.type && item.date === input.date) {
          return { ...item, ...modifiedItem };
        } else {
          return item;
        }
      });

      setDataSource(updatedDataSource);
    }

  }

  const removeentry = (date, time, kno, type) => {
    // Create a new item object

    const shouldRemoveItem = (item) => {
      return item.kno === kno && item.type === type;
    };

    // Remove items from the DataSource based on user input criteria (kno and type)

    const updatedDataSource = DataSource.filter((item) => !shouldRemoveItem(item));

    // Update the state with the modified DataSource
    setDataSource(updatedDataSource);

  }

  const handleFocus = () => {
    if (localStorage.getItem("digri") === "No") {
      if (newedit === "new") {
        if (input.type === 'म्हैस') {
          if (localStorage.getItem("autosnf") === "Yes") {
            setInput({ ...input, snf: '9' });
          }
        } else if (input.type === 'गाय') {
          if (localStorage.getItem("autosnf") === "Yes") {
            setInput({ ...input, snf: '8.5' });
          }
        }
      }
    }
  };

  const handleBlur = () => {
    if (input.fat !== "") {
      setInput(prevInput => ({
        ...prevInput,
        fat: parseFloat(input.fat).toFixed(1),
        snf: parseFloat(input.snf).toFixed(1)  // Convert to one decimal place
      }));
    }
  };

  const offexp = () => {
    setExpanded(false);
  }

  async function opensr(sr) {
    localStorage.setItem("sankdate", input.date);
    localStorage.setItem("sanktime", input.time)
    localStorage.setItem("glcustsr", sr);
    navigate("/Dashboard");
    setsubmenu("customer");
    setNewcustsource("sank");
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  const handleChangeactive = (event) => {
    setSendmsg(!sendMsg);
  }

  const handleCustsank = (event) => {
    setDataSource([]);
    SetCustsank(!custSank);
  }

  useEffect(() => {
    setInput({ date: new Date().toISOString().substr(0, 10), time: "", kno: "", name: "", type: "", liter: "", fat: "", snf: "", rate: "", amt: "" });
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [custSank])

  return (
    <div className="sanklanmain">
      <div className="firstrowsank">
        <img onClick={props.visibility} className='back' src={back} alt="" />
        <div className="colmain">
          <div className="firstrow2">
            <div className="rowsank">
              {
                custSank ?
                  <div className="rowsank">
                    <div className="mb-2">
                      <span className='subheadsank'>खाते क्रमांक</span>
                      <input onBlur={getsanksanklandata} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2">
                      <span className='subheadsank'>सभासदाचे नाव <span className='prev'>{input.vibhag ? " (" + input.vibhag + ")" : ''}</span></span>
                      <input type="text" disabled className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                    </div>
                  </div>
                  :
                  <div className="rowsank">
                    <div className="mb-2">
                      <span onBlur={getsanksanklandata} onClick={sendnotification} className='subheadsank'>दिनांक</span>
                      <input max={today} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2">
                      <span className='subheadsank'>वेळ</span>
                      <select onBlur={getsanksanklandata} onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} list="encodings" autoComplete='off' className={newedit === "new" ? "form-control" : "form-control editmode"} id="time" name="time" value={input.time} onChange={handleChange}>
                        <option key={0} value="वेळ निवडा">वेळ निवडा</option>
                        <option key={1} value="सकाळ">सकाळ</option>
                        <option key={2} value="संध्याकाळ">संध्याकाळ</option>
                      </select>
                    </div>
                  </div>
              }
            </div>

            {
              tcust.tcust ?
                <>
                  <div className="tbox-container">
                    <div className={` ${expanded ? 'expanded' : 'tbox'}`} >
                      <div className="row1sanklan">
                        <span><span onClick={e => handleClick("total")} className="subheadsank">कालचे ए.सभासद -</span> <span onClick={e => handleClick("total")} className="subheadsankgreen">{tcust.tcust}</span> , <span onClick={e => handleClick("complete")} className="subheadsank">आजचे ए.सभासद-</span> <span onClick={e => handleClick("complete")} className="subheadsankgreen">{tcust.tcompleted}</span> , <span onClick={e => handleClick("pending")} className="subheadsank">संकलन पेंडिंग</span><span onClick={e => handleClick("pending")} className="subheadsankcolor"></span></span>
                        {expanded ? <img onClick={offexp} className='back2' src={close} alt="" /> : ""}
                      </div>
                      {expanded ?
                        <div className="table-containersank">
                          <table id="myTable" className="table table-bordered">
                            <thead>
                              <tr>
                                <th className='thsmall' scope="col">क्र.</th>
                                <th className='thsmall' scope="col">खाते क्र.</th>
                                <th className='thlarge' scope="col">नाव</th>
                                <th className='thsmall' scope="col">प्रकार</th>
                                <th className='thsmall' scope="col">एक्शन</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                flag === "total" ?
                                  custdata.map((user, index) =>
                                    <tr key={index}>
                                      <td className='thsmall'>{index + 1}</td>
                                      <td className='thsmall'>{user.kno}</td>
                                      <td className='thsmall'>{user.cname}</td>
                                      <td className='thsmall'>{user.type}</td>
                                      <td><UilEditAlt onClick={() => { opensr(user.kno) }} className="icsank" /></td>

                                    </tr>
                                  )
                                  : flag === "complete" ?
                                    DataSource
                                      // Filter based on kno existence in DataSource
                                      .map((user, index) => (
                                        <tr key={index}>
                                          <td className='thsmall'>{index + 1}</td>
                                          <td className='thsmall'>{user.kno}</td>
                                          <td className='thsmall'>{user.name}</td>
                                          <td className='thsmall'>{user.type}</td>
                                          <td><UilEditAlt className="icsank" /></td>
                                        </tr>
                                      ))
                                    : flag === "pending" ?

                                      custdata
                                        .filter(user => !DataSource.some(data => data.kno === user.kno)) // Filter based on kno existence in DataSource
                                        .map((user, index) => (
                                          <tr key={index}>
                                            <td className='thsmall'>{index + 1}</td>
                                            <td className='thsmall'>{user.kno}</td>
                                            <td className='thsmall'>{user.cname}</td>
                                            <td className='thsmall'>{user.type}</td>
                                            <td><UilEditAlt className="icsank" /></td>
                                            {/* onClick={() => { opensr(user.kno) }} */}
                                          </tr>
                                        ))
                                      : ""
                              }
                            </tbody>
                          </table>
                        </div>
                        : ""
                      }
                    </div>
                  </div>
                </>
                :
                <div className="tbox-container">
                  <div className={` ${expanded ? 'expanded2' : 'tbox2'}`} >

                  </div>
                </div>
            }

            {/* <div className={tcust.tcust ? 'checkboxes noti' : 'checkboxes'}>
              <div className={tcust.tcust ? 'form-check noti' : 'form-check'}>
                <input className="form-check-input" type="checkbox" value="" checked={sendMsg} id="activestatus" onChange={handleChangeactive} />
                <label className="form-check-label" htmlFor="activestatus">
                  मेसेज पाठविणे
                </label>
              </div>
              <div className={tcust.tcust ? 'form-check noti' : 'form-check'}>
                <input className="form-check-input" type="checkbox" value="" checked={custSank} id="custsank" onChange={handleCustsank} />
                <label className="form-check-label" htmlFor="custsank">
                  सभासदानुसार संकलन
                </label>
              </div>
            </div> */}
            <img onClick={getsanksanklandata} className='refresh' src={refresh} alt="" />
          </div>
        </div>
      </div>

      <div className='linesank' />
      <div className="row1sank">
        {
          custSank ?
            <div className="rowsank">
              <div className="mb-2">
                <span onClick={sendnotification} className='subheadsank'>दिनांक</span>
                <input max={today} onBlur={getsanksanklandata} onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
              </div>
              <div className="mb-2">
                <span className='subheadsank'>वेळ</span>
                <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} list="encodings" autoComplete='off' className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} id="time" name="time" value={input.time} onChange={handleChange}>
                  <option key={0} value="वेळ निवडा">वेळ निवडा</option>
                  <option key={1} value="सकाळ">सकाळ</option>
                  <option key={2} value="संध्याकाळ">संध्याकाळ</option>
                </select>
              </div>
            </div>
            :
            <div className="row1sank">
              <div className="mb-2">
                <span className='subheadsank'>खाते क्रमांक</span>
                <input onBlur={checkonoff} onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
              </div>
              <div className="mb-2">
                <span className='subheadsank'>सभासदाचे नाव <span className='prev'>{input.vibhag ? " (" + input.vibhag + ")" : ''}</span></span>
                <input type="text" disabled className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
              </div>
            </div>
        }
        <div className="mb-2">
          <span className='subheadsank'>प्रकार</span>
          <select onBlur={getoldentry} onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} id="typesank" name="type" value={input.type} onChange={handleChange} >
            <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
            <option key={1} value="म्हैस">म्हैस</option>
            <option key={2} value="गाय">गाय</option>
          </select>
        </div>
        <div className="mb-2">
          <span className='subheadsank'>लिटर <span className='prev'>{Prev.liter ? " (" + Prev.liter + ")" : ''}</span></span>
          <input onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="liter" name="liter" value={input.liter} onChange={handleChange} ></input>
        </div>
        <div className="mb-2">
          <span className='subheadsank'>फॅट <span className='prev'>{Prev.fat ? " (" + Prev.fat + ")" : ''}</span></span>
          <input onKeyDown={e => handleEnterKey(e, 5)} onBlur={handleBlur} ref={ref => (inputRefs.current[5] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="fat" name="fat" value={input.fat} onChange={handleChangefs} ></input>
        </div>
        {
          localStorage.getItem("digri") === "Yes" ?
            <div className="mb-2">
              <span className='subheadsank'>डिग्री</span>
              <input onKeyDown={e => handleEnterKey(e, 6)} onBlur={handleBlur} ref={ref => (inputRefs.current[6] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="digri" name="digri" value={input.digri} onChange={handleChangefs} ></input>
            </div>
            : ''
        }
        <div className="mb-2">
          <span className='subheadsank'>एसएनएफ <span className='prev'>{Prev.snf ? " (" + Prev.snf + ")" : ''}</span></span>
          <input onKeyDown={e => handleEnterKey(e, localStorage.getItem("digri") === "Yes" ? 7 : 6)} onBlur={handleBlur} ref={ref => (inputRefs.current[localStorage.getItem("digri") === "Yes" ? 7 : 6] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="snf" name="snf" value={input.snf} onChange={handleChangefs} onFocus={handleFocus} ></input>
        </div>
        <div className="mb-2">
          <span className='subheadsank'>दर</span>
          <input disabled type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="rate" name="rate" value={input.rate} onChange={handleChange} ></input>
        </div>
        <div className="mb-2">
          <span className='subheadsank'>रक्कम</span>
          <input disabled type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="amtsank" name="amt" value={parseFloat(input.amt).toFixed(2)} onChange={handleChange} ></input>
        </div>

        <div className="mb-2 dddd">
          <span className='subheadsankwhite'>-</span>
          <button id='savesank' onClick={savesanklan} onKeyDown={e => handleEnterKeybutton(e, 1)} ref={ref => (inputRefs.current[localStorage.getItem("digri") === "Yes" ? 8 : 7] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
            {newedit === "new" ?
              "सेव्ह" :
              "अपडेट"
            }
          </button>

        </div>

        <div className="mb-2 dddd">
            <span className='subheadsankwhite'>-</span>
            <button id='printsank' onClick={savesanklan} onKeyDown={e => handleEnterKeybutton(e, 1)} ref={ref => (inputRefs.current[localStorage.getItem("digri") === "Yes" ? 9 : 8] = ref)} type="submit" className="btn btn-primary sanksmall">
              प्रिंट
            </button>
          </div>

        <div className="mb-2 lastimg">
        </div>
      </div>
      {
        custSank ?
          <div className="firstrow2">

            <div className="table-containersank">
              <span className='label2'>{custSank && input.name ? input.name + " यांचे संकलन" : ''}</span>
              <table id="myTable" className="table table-bordered">
                <thead>
                  <tr>
                    <th className='thsmallkno' scope="col">दिनांक</th>
                    <th className='thsmallkno' scope="col">वेळ</th>
                    <th className='thsmallkno' scope="col">प्रकार</th>
                    <th className='thsmallkno' scope="col">विभाग</th>
                    <th className='thsmallkno' scope="col">लिटर</th>
                    <th className='thsmallkno' scope="col">फॅट</th>
                    <th className='thsmallkno' scope="col">एसएनएफ</th>
                    <th className='thsmallkno' scope="col">दर</th>
                    <th className='thsmallkno' scope="col">रक्कम</th>
                    <th className='thsmallkno' scope="col">एक्शन</th>
                  </tr>
                </thead>
                <tbody>
                  {value.length > 0 ? tableFilter.map((user, index) =>
                    <tr key={index}>
                      <td className='thsmall'>{user.date}</td>
                      <td className='thsmall'>{user.time}</td>
                      <td className='thsmall'>{user.type}</td>
                      <td className='thsmall'>{user.vibhag}</td>
                      <td className='thsmall'>{user.liter}</td>
                      <td className='thsmall'>{user.fat}</td>
                      <td className='thsmall'>{user.snf}</td>
                      <td className='thsmall'>{user.rate}</td>
                      <td className='thsmall'>{user.amt}</td>
                      <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.time, user.kno, user.name, user.vibhag, user.type, user.liter, user.fat, user.snf, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.date, user.time, user.kno, user.type) }} v className="icsank" /></td>
                    </tr>
                  )
                    :
                    DataSource.map((user, index) =>
                      <tr key={index}>
                        <td className='thsmall'>{user.date}</td>
                        <td className='thsmall'>{user.time}</td>
                        <td className='thsmall'>{user.type}</td>
                        <td className='thsmall'>{user.vibhag}</td>
                        <td className='thsmall'>{user.liter}</td>
                        <td className='thsmall'>{user.fat}</td>
                        <td className='thsmall'>{user.snf}</td>
                        <td className='thsmall'>{user.rate}</td>
                        <td className='thsmall'>{user.amt}</td>
                        <td className='thsmall'><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.time, user.kno, user.name, user.vibhag, user.type, user.liter, user.fat, user.snf, user.rate, user.amt, user.digri) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.date, user.time, user.kno, user.type) }} v className="icsank" /></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>

            </div>
          </div>
          :
          <div className="firstrow2">
            <div className="table-containersank">
              <table id="myTable" className="table table-bordered">
                <thead>
                  <tr>
                    <th className='thsmall' scope="col">क्र.</th>
                    <th className='thsmall' scope="col">खाते क्रमांक</th>
                    <th className='thlarge' scope="col">नाव</th>
                    <th className='thsmall' scope="col">प्रकार</th>
                    <th className='thsmall' scope="col">विभाग</th>
                    <th className='thsmall' scope="col">लिटर</th>
                    <th className='thsmall' scope="col">फॅट</th>
                    {
                      fatcol ?
                        // <>
                        //   <th className='thsmall' scope="col">कालची फॅट</th>
                        //   <th className='thsmall' scope="col">फॅट फरक</th>
                        // </>
                        ''
                        :
                        ''
                    }
                    <th className='thsmall' scope="col">एसएनएफ</th>
                    <th className='thsmall' scope="col">दर</th>
                    <th className='thsmall' scope="col">रक्कम</th>
                    <th className='thactionsank' scope="col">एक्शन</th>
                  </tr>
                </thead>
                <tbody>
                  {value.length > 0 ? tableFilter.map((user, index) =>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user.kno}</td>
                      <td>{user.name}</td>
                      <td>{user.type}</td>
                      <td>{user.vibhag}</td>
                      <td>{user.liter}</td>
                      <td>{user.fat}</td>
                      {
                        fatcol ?
                          // <>
                          //   <td>{user.previous_fat}</td>
                          //   <td>{user.differencefat}</td>
                          // </>
                          ''
                          :
                          ''
                      }
                      <td>{user.snf}</td>
                      <td>{user.rate}</td>
                      <td>{user.amt}</td>
                      <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.time, user.kno, user.name, user.vibhag, user.type, user.liter, user.fat, user.snf, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.date, user.time, user.kno, user.type) }} v className="icsank" /></td>
                    </tr>
                  )
                    :
                    DataSource.map((user, index) =>
                      <tr key={index}>
                        <td className='thsmallaudit'>{index + 1}</td>
                        <td className='thsmallaudit'>{user.kno}</td>
                        <td className='thsmallaudit'>{user.name}</td>
                        <td className='thsmall2'>{user.type}</td>
                        <td className='thsmall2'>{user.vibhag}</td>
                        <td className='thsmallaudit'>{user.liter}</td>
                        <td className='thsmallaudit'>{user.fat}</td>

                        {
                          fatcol ?
                            // <>
                            //   <td className='thsmall'>{user.previous_fat}</td>
                            //   <td className={user.differencefat < 0 ? 'thsmallred' : 'thsmallgreen'}>
                            //     {user.differencefat}
                            //   </td>
                            // </>
                            ''
                            :
                            ''
                        }
                        <td className='thsmallaudit'>{user.snf}</td>
                        <td className='thsmallaudit'>{user.rate}</td>
                        <td className='thsmallaudit'>{user.amt ? parseFloat(user.amt.replace(/,/g, '')).toFixed(2) : '0.00'}</td>
                        <td className='thactionsank'><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.time, user.kno, user.name, user.vibhag, user.type, user.liter, user.fat, user.snf, user.rate, user.amt, user.digri) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr, user.date, user.time, user.kno, user.type) }} v className="icsank" /></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
      <div className="toboxbelow2">
        {tcust.tliter ? (
          <>
            <div className="mhbox">
              <img className='back' src={buflogo} alt="" />
              {tcust.tmh && parseFloat(tcust.tmh) > 0 && (
                <>
                  <span className="subheadsank">लिटर:</span>
                  <span className="subheadsankgreen2"> {tcust.tmh}</span>
                  <span className="subheadsank">सरासरी फॅट/SNF:</span>
                  <span className="subheadsankgreen2">{tcust.mhavg + " / " + tcust.mhsnfavg}</span>
                  <span className="subheadsank">रक्कम:</span>{" "}
                  <span className="subheadsankgreen2">₹{tcust.tamtmh}</span>
                </>
              )}
            </div>

            <div className="mhbox">
              <img className='back' src={cowlogo} alt="" />
              {tcust.tgay && parseFloat(tcust.tgay) > 0 && (
                <>
                  <span className="subheadsank">लिटर:</span>
                  <span className="subheadsankgreen2"> {tcust.tgay}</span>
                  <span className="subheadsank">सरासरी फॅट/SNF:</span>
                  <span className="subheadsankgreen2">{tcust.gayavg + " / " + tcust.gaysnfavg}</span>
                  <span className="subheadsank">रक्कम:</span>{" "}
                  <span className="subheadsankgreen2">₹{tcust.tamtgay}</span>
                </>
              )}
            </div>

            <div className="mhgaybox">
              <div className="sankverti">
                <span className="subheadsank">ए.लिटर:</span>{" "}
                <span className="subheadsankgreen2">{tcust.tliter}</span>
              </div>
              <div className="sankverti">
                <span className="subheadsank">ए.रक्कम:</span>{" "}
                <span className="subheadsankgreen2">{tcust.tamt}</span>
              </div>
            </div>
          </>

        ) : (
          <>
            <span className="subheadsankgreen">कोणत्याही एंट्रीज उपलब्ध नाहीत</span>

          </>
        )}

      </div>
      <ToastContainer />
    </div >
  )
}

export default Sanklan

import React, { useContext, useEffect, useRef, useState } from 'react';
import './Billprint2.css';

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons'
import { green, lightGreen } from '@mui/material/colors';
import Globalcontext from '../../Context/Globalcontext';
import Cowsmall from "../../imgs/cowsmall.gif";
import Grass from "../../imgs/grass.png";
import { LanRounded } from '@mui/icons-material';

// Register the font using the imported variable
Font.register({
    family: 'Lato-Italic',
    src: LatoItalicFont,
});

const Billprint = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [customerData, setCustomerData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [loadedPages, setLoadedPages] = useState(1);
    let bothornotmain = "";


    const loadMorePages = () => {
        setIsLoading(true);
        const pagesToLoad = 1; // Change this to load more pages

        setTimeout(() => {
            setLoadedPages(loadedPages + pagesToLoad);
            setIsLoading(false);
        }, 1000);
    };

    useEffect(() => {
        console.log(localStorage.getItem("customerData"));
        setTimeout(() => {

            if (JSON.parse(localStorage.getItem("customerData"))) {
                setCustomerData(JSON.parse(localStorage.getItem("customerData")));
                // Set loading to false when data is ready
                setIsLoading(false);
            }
        }, 200);
    }, []);

    const pageDataLength = parseFloat(Object.keys(customerData).length) / 2;
    const minutes = Math.floor(pageDataLength / 60);
    const seconds = pageDataLength % 60;

    return (
        <div className="billprintmain">
            <PDFViewer className='pdf'>
                <Document>
                    {Object.keys(customerData).map((kno, index) => (
                        <PageTemplate key={index} data={customerData[kno]} index={index} kno={kno} min={minutes} sec={seconds} pages={parseFloat(pageDataLength) * 2} />
                    ))}
                </Document>
            </PDFViewer>
        </div>
    );
};

function PageTemplate({ data, index, kno, min, sec, pages }) {
    const [kapat, setKapat] = useState({});

    const [isGenerating, setIsGenerating] = useState(true);

    const generatePageContent = async () => {
        // Simulate page generation
        await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the duration as per your page generation time
        setIsGenerating(false); // Set isGenerating to false when the page is ready
    };

    useEffect(() => {
        generatePageContent();
    }, []);


    const getDatesInRange = (date1, date2) => {
        const dates = [];
        const currentDate = new Date(date1);

        while (currentDate <= new Date(date2)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const getTableDataWithZeros = (tableData, date1, date2, type, time) => {
        if (!Array.isArray(tableData)) {
            return Array.from({ length: getDatesInRange(date1, date2).length }, (_, index) => ({
                sr: '',
                date: getDatesInRange(date1, date2)[index],
                time: time,
                kno: '',
                type: type,
                liter: '0',
                fat: '0',
                snf: '0',
                rate: '0',
                amt: '0.00',
                date1: date1,
                date2: date2,
                kendra: '',
            }));
        }

        const dateRange = getDatesInRange(date1, date2);
        const tableWithData = [];

        dateRange.forEach(date => {
            const matchingEntry = tableData.find(
                entry => entry.date === date && entry.type === type && entry.time === time
            );

            if (matchingEntry) {
                tableWithData.push(matchingEntry);
            } else {
                // Create a record with zero values
                tableWithData.push({
                    sr: '',
                    date: date,
                    time: time,
                    kno: '',
                    type: type,
                    liter: '0',
                    fat: '0',
                    snf: '0',
                    rate: '0',
                    amt: '0.00',
                    date1: date1,
                    date2: date2,
                    kendra: '',
                });
            }
        });

        return tableWithData;
    };


    const styles = StyleSheet.create({
        page: {
            paddingTop: 10,
            flexDirection: 'row',
            backgroundColor: '#FFFFFF',
            justifyContent: 'center', // Center horizontally
            marginLeft: 14, // Increase the left margin
            marginRight: 13

        },
        pageWithMargin: {
            backgroundColor: "lightblue"
        },
        header: {
            position: 'absolute',
            top: 0, // Adjust this value to change the vertical position
            left: 0,
            right: 0,
            textAlign: 'center',
        },
        loading: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',
            justifyContent: 'space-between',
        },
        table: {
            display: 'table',
            width: 'auto', // Adjust as needed to fit four tables in a row
            margin: 5,
            marginLeft: -1,
        },
        tableone: {
            display: 'table',
            width: '18%', // Adjust as needed to fit four tables in a row
            margin: 5,
        },
        tableCellone: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: 3,
            flex: 1, // Remove width and use flex to distribute space equally
        },

        tableCellonebig: {
            border: '0.5px solid lightgray',
            fontSize: 9.5,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '85mm', // Set the width of the individual cell
            fontWeight: 'bold'
        },
        tableCellonebig2: {
            border: '0.5px solid #000',
            fontSize: 9.5,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '10px',
            width: '85mm', // Set the width of the individual cell
            fontWeight: 'bold'
        },
        tableCellonebigdata: {
            border: '0.5px solid lightgray',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '3mm',
            width: '85mm', // Set the width of the individual cell
            fontWeight: 'bold'
        },
        tableRowone: {
            flexDirection: 'row',
            width: '65%',
            height: 17,
            textAlign: 'right',
            marginLeft: -5,
        },
        tableRowonebig: {
            flexDirection: 'row',
            width: '80%',
            height: 17,
            textAlign: 'right',
            marginLeft: -5,
        },
        tableHeaderone: {
            flexDirection: 'row',
            width: '65%',
            height: 17,
            textAlign: 'right',
            border: '0.5px solid #000',
            marginLeft: -5,
            backgroundColor: 'lightgray'
        },
        tableHeaderonebig: {
            flexDirection: 'row',
            width: '80%',
            height: 17,
            textAlign: 'right',
            border: '0.5px solid #000',
            marginLeft: -5,
            backgroundColor: 'lightgray'
        },
        allbill: {
            flexDirection: 'row',
            width: '65%',
            textAlign: 'right',
            height: 17,
            marginLeft: -5,
            backgroundColor: 'lightgray',
            marginTop: 5
        },
        allbillbig: {
            border: '0.5px solid lightgray',
            flexDirection: 'row',
            width: '80%',
            textAlign: 'right',
            height: 17,
            marginLeft: -5,
            backgroundColor: 'lightgray',
            marginTop: 5
        },
        tableRow: {
            flexDirection: 'row',
            height: 17,
            marginLeft: 3,
        },
        tableRow2: {
            flexDirection: 'row',
            height: 17,
            marginLeft: 3,
            marginTop: 5,
            backgroundColor: 'lightgray'
        },
        line: {
            flexDirection: 'row',
            backgroundColor: 'lightgray',
            height: 1,
            marginTop: -2,
            width: '95vw',
        },
        tableHeader: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '5mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadersm: {
            border: '0.5px solid #000',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '11mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadersmbig: {
            border: '0.5px solid #000',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '14mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadersmbig2: {
            border: '0.5px solid #000',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '13mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadersmbig3: {
            border: '0.5px solid #000',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '19mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadermed: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '13mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadermedbig: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 9,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '13mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadermed2: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '19mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadermed2big: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '19mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableCell: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '1mm', // Set the width of the individual cell
        },
        tableCellbig: {
            border: '0.5px solid lightgray',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '1mm', // Set the width of the individual cell
        },
        tableCellsm: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '11mm', // Set the width of the individual cell

        },
        tableCellsmbig: {
            border: '0.5px solid lightgray',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '14mm', // Set the width of the individual cell

        },
        tableCellmed: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '1mm',
            width: '13mm', // Set the width of the individual cell
        },
        tableCellmedbig: {
            border: '0.5px solid lightgray',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '1mm',
            width: '13mm', // Set the width of the individual cell
        },
        tableCellmed2: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '1mm',
            width: '19mm', // Set the width of the individual cell
        },
        tableCellmed2big: {
            border: '0.5px solid lightgray',
            fontSize: 10,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '1mm',
            width: '19mm', // Set the width of the individual cell
        },
        latoText: {
            fontFamily: 'Lato-Italic',
            fontSize: 15,
            marginBottom: 5,
            textAlign: 'center',
            width: '100%',
            fontWeight: 'bold',
            marginTop: 1,
        },
        headtextsmall: {
            fontFamily: 'Lato-Italic',
            fontSize: 10,
            fontWeight: 'bold',
        },
        headtextsmall2: {
            fontFamily: 'Lato-Italic',
            fontSize: 11,

            marginRight: 20,
            marginTop: -5,
            fontWeight: 'bold',
        },
        headtextsmall3: {
            fontFamily: 'Lato-Italic',
            fontSize: 14,
            color: 'rgb(156, 44, 24)',
            fontWeight: 'bold',
            alignSelf: 'center'
        },
        headtextsmall4: {
            fontFamily: 'Lato-Italic',
            fontSize: 10,
            color: 'rgb(156, 44, 24)',
            fontWeight: 'bold',
            alignSelf: 'center'
        },
        headtextsmallleft1: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            marginLeft: -70,
            marginTop: -5,
            fontWeight: 'bold',
        },
        headtextsmallleft2: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            marginLeft: 0,
            marginTop: -5,
            fontWeight: 'bold',
        },
        headtextsmallleft3: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            marginLeft: 0,
            marginTop: -5,
            fontWeight: 'bold',
        },
        headtextsmallleft4: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            marginRight: -50,
            marginTop: -5,
            fontWeight: 'bold',
        },

        headtextsmallleft1big: {
            fontFamily: 'Lato-Italic',
            fontSize: 11,
            marginLeft: -70,
            marginTop: -5,
            fontWeight: 'bold',
        },
        headtextsmallleft2big: {
            fontFamily: 'Lato-Italic',
            fontSize: 11,
            marginLeft: 0,
            marginTop: -5,
            fontWeight: 'bold',
        },

        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
        },
        row1: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 5
        },
        row2: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 140,
            paddingRight: 140,
            backgroundColor: 'lightgray',
        },
        row2both: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 180,
            paddingRight: 180,
            backgroundColor: 'lightgray',
        },
        row2single: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '250px',
            backgroundColor: 'lightgray',
        },
        column1: {
            display: 'flex',
            flexDirection: 'column',
            width: '23%',
            justifyContent: 'start',
            alignItems: 'flex-start', // Align text to the left
            paddingLeft: 5,
            paddingRight: 5,
            marginLeft: 20,
        },
        column2: {
            display: 'flex',
            flexDirection: 'column',
            width: '15%',
            justifyContent: 'start',
            alignItems: 'flex-start', // Align text to the left
            paddingLeft: 5,
            paddingRight: 5,
        },
        column3: {
            border: '0.5px solid #000',
            display: 'flex',
            flexDirection: 'column',
            width: '17%',
            height: '89%',
            justifyContent: 'start',
            margin: 5,
            marginLeft: 2,
            alignItems: 'flex-start', // Align text to the left
        },
        column3blank: {
            display: 'flex',
            flexDirection: 'column',
            width: '17%',
            height: '89%',
            justifyContent: 'start',
            margin: 5,
            marginLeft: 2,
            alignItems: 'flex-start', // Align text to the left
        },

        grayhead: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingBottom: 3,
            justifyContent: 'center',
            alignItems: 'flex-start', // Align text to the left
            backgroundColor: 'lightgray',
        },

        column1Row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        columnbottom: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: 3
        },
        simplerow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

        },
        simplecol: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: -1
        },
        column1Rowgray: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: 4,
            marginTop: 3,
            marginBottom: 5,
            border: '0.5px solid black',
            backgroundColor: 'lightgray'
        },
        column1Label: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'left', // Align labels to the left
        },
        column1Labelgray: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'center', // Align labels to the left
        },
        column1Labelgreen: {
            fontFamily: 'Lato-Italic',
            fontSize: 12,
            fontWeight: 'bold',
            textAlign: 'center', // Align labels to the left
        },
        column1Value: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'right', // Align values to the right
        },
        pageBreak: {
            pageBreakBefore: 'always',
        },
        totalsRow: {
            flexDirection: 'row',
            borderTop: '1px solid #000',
            paddingTop: 5,
            paddingLeft: 2,
        },
        totalsCell: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            flex: 1,
            fontSize: 8,
            width: 2,
            textAlign: 'center',
        },
        imagerow: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: -15
        },
        image: {
            width: 60, // Adjust the width as needed
            height: "28", // Maintain aspect ratio
            marginTop: -3,
            alignSelf: "center",
            marginLeft: -20
        },
        imgtext: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'center', // Align labels to the left
        },
        tableCellgreen: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            flex: 1,
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'Lato-Italic',
            color: 'green',
            width: 2,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        tableCellred: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            flex: 1,
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'Lato-Italic',
            color: 'red',
            width: 3,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        loadingSpinner: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 16,
            fontWeight: 'bold',
            color: 'gray',
        },
        tableHeadersmbigacc: {
            border: '0.5px solid #000',
            fontSize: 9,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '16mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadersmbigaccblank: {
            fontSize: 9,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '15mm', // Set the width of the individual cell
        },
        tableHeadersmbigaccwhite: {
            border: '0.5px solid #000',
            fontSize: 9,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingLeft: '2px',
            paddingRight: '5px',
            width: '16mm', // Set the width of the individual cell
        },
        tableRowacc: {
            flexDirection: 'row',
            height: 18,
            marginLeft: 3,
            textAlign: 'center',

        },

    });

    const knoData = data;
    const groupByTypeAndTime = {};

    let tlitermhmor = 0;
    let tlitermhevn = 0;
    let tlitergaymor = 0;
    let tlitergayevn = 0;

    // Group data by 'type' and 'time'
    knoData.forEach(entry => {
        const key = entry.type + '-' + entry.time;
        tlitermhmor = entry.tlitermhmor;
        tlitermhevn = entry.tlitermhevn;
        tlitergaymor = entry.tlitergaymor;
        tlitergayevn = entry.tlitergayevn;
        if (!groupByTypeAndTime[key]) {
            groupByTypeAndTime[key] = [];
        }
        groupByTypeAndTime[key].push(entry);
    });

    const tableDataArrays = ['म्हैस-सकाळ', 'म्हैस-संध्याकाळ', 'गाय-सकाळ', 'गाय-संध्याकाळ'].map((typeTimeKey) => {
        const [type, time] = typeTimeKey.split('-');
        const entries = groupByTypeAndTime[typeTimeKey]; // You should define 'groupByTypeAndTime' based on your data structure.
        return getTableDataWithZeros(entries, knoData[0].date1, knoData[0].date2, type, time);
    });

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
        return new Date(inputDate).toLocaleDateString('IN', options);
    }


    const calculateSumOfAmtColumns = (tableDataArray) => {
        const sums = Array.from({ length: tableDataArray[0].length }, () => 0); // Initialize an array with zeros based on the length of the first table

        // Loop through the tables and calculate sums for each record
        tableDataArray.forEach((tableDataWithZeros) => {
            tableDataWithZeros.forEach((entry, entryIndex) => {
                sums[entryIndex] += parseFloat(entry.amt);
            });
        });
        return sums;
    };

    const sumsOfAmtColumns = calculateSumOfAmtColumns(tableDataArrays);
    //  console.log("sumsOfAmtColumns: ", sumsOfAmtColumns);
    const sumOfFifthTable = sumsOfAmtColumns.reduce((total, sum) => total + parseFloat(sum), 0).toFixed(2);
    let totalOfTotalLiter = 0;
    let totalOfTotalFat = 0;
    let tkapat = 0;
    let tyene = 0;
    let ada = 0;

    let zeroflagmh = false;
    let zeroflaggay = false;

    let zeroflagmhevn = false;
    let zeroflaggayevn = false;

    let totalLiterMh = 0;
    let totalLiterGay = 0;

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [isboth, setIsboth] = useState(false);

    useEffect(() => {
        if (zeroflagmh && zeroflaggay) {
            //   console.log("in both");
            setIsboth(true);
        }
    }, [zeroflagmh, zeroflaggay]);

    const renderTable = (tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot) => {
        return (
            <View key={typeTimeKey} style={styles.table}>
                {/* Table Header */}
                <View style={styles.tableRow}>
                    {tableIndex == dateflag ? ( // Render "दिनांक" only for the first table
                        <Text style={[bothornot === "both" ? styles.tableHeadersm : styles.tableHeadersmbig, { width: bothornot === "both" ? 38 : 38 }]}>{"दिनांक"}</Text>
                    ) : null}
                    <Text style={bothornot === "both" ? styles.tableHeadersm : styles.tableHeadersmbig}>{"लिटर"}</Text>
                    <Text style={bothornot === "both" ? styles.tableHeadersm : styles.tableHeadersmbig}>{"फॅट"}</Text>
                    <Text style={bothornot === "both" ? styles.tableHeadersm : styles.tableHeadersmbig}>{"SNF"}</Text>
                    <Text style={bothornot === "both" ? styles.tableHeadermed : styles.tableHeadersmbig2}>{"दर"}</Text>
                    <Text style={bothornot === "both" ? styles.tableHeadermed2 : styles.tableHeadersmbig3}>{"रक्कम"}</Text>
                </View>
                {/* Table Data */}
                {tableDataWithZeros.map((entry, entryIndex) => (
                    <View key={entryIndex} style={styles.tableRow}>
                        {tableIndex == dateflag ? ( // Render "दिनांक" only for the first table
                            <Text style={[bothornot === "both" ? styles.tableCell : styles.tableCellbig, { width: 38 }]}>
                                {entry.date.substr(5).split('-').reverse().join('/')}
                            </Text>
                        ) : null}
                        {
                            entry.liter === '0' ?
                                <>
                                    <Text style={bothornot === "both" ? styles.tableCellsm : styles.tableCellsmbig}></Text>
                                    <Text style={bothornot === "both" ? styles.tableCellsm : styles.tableCellsmbig}></Text>
                                    <Text style={bothornot === "both" ? styles.tableCellsm : styles.tableCellsmbig}></Text>
                                    <Text style={bothornot === "both" ? styles.tableCellmed : styles.tableCellmedbig}></Text>
                                    <Text style={bothornot === "both" ? styles.tableCellmed2 : styles.tableCellmed2big}></Text>
                                </>
                                :
                                <>
                                    <Text style={bothornot === "both" ? styles.tableCellsm : styles.tableCellsmbig}>{entry.liter}</Text>
                                    <Text style={bothornot === "both" ? styles.tableCellsm : styles.tableCellsmbig}>{entry.fat}</Text>
                                    <Text style={bothornot === "both" ? styles.tableCellsm : styles.tableCellsmbig}>{entry.snf}</Text>
                                    <Text style={bothornot === "both" ? styles.tableCellmed : styles.tableCellmedbig}>{parseFloat(entry.rate).toFixed(2)}</Text>
                                    <Text style={bothornot === "both" ? styles.tableCellmed2 : styles.tableCellmed2big}>{parseFloat(entry.amt).toFixed(2)}</Text>
                                </>
                        }
                    </View>
                ))}
                {/* Totals Row */}
                <View style={styles.tableRow2}>
                    {tableIndex == dateflag ? ( // Render "दिनांक" only for the first table
                        <Text style={[bothornot === "both" ? styles.tableCellsm : styles.tableHeadersmbig, { width: bothornot === "both" ? 35 : 39 }]}>एकूण</Text>
                    ) : null}
                    <Text style={[bothornot === "both" ? styles.tableCellsm : styles.tableHeadersmbig]}>{totalLiter.toFixed(2)}</Text>
                    <Text style={[bothornot === "both" ? styles.tableCellsm : styles.tableHeadersmbig]}>{avgfat ? avgfat.toFixed(1) : "0.0"}</Text>
                    <Text style={[bothornot === "both" ? styles.tableCellsm : styles.tableHeadersmbig]}>{avgsnf ? avgsnf.toFixed(1) : "0.0"}</Text>
                    <Text style={[bothornot === "both" ? styles.tableCellsm : styles.tableHeadersmbig2]}></Text>
                    <Text style={[bothornot === "both" ? styles.tableCellsm : styles.tableHeadersmbig3]}>{totalAmt.toFixed(2)}</Text>
                </View>
            </View>
        );
    };

    let dateflag = "";
    let bothornot = "";
    let milktype = "";

    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor != null && tlitergayevn != null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor == null && tlitergayevn == null) {
        dateflag = 0;
        bothornot = "not";
        milktype = "म्हैस";

    }

    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor == null && tlitergayevn == null) {
        dateflag = 0;
        bothornot = "not";
        milktype = "म्हैस";

    }

    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor != null && tlitergayevn == null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor != null && tlitergayevn != null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor != null && tlitergayevn != null) {
        dateflag = 2;
        bothornot = "not";
        milktype = "गाय";

    }

    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor != null && tlitergayevn == null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor == null && tlitergayevn != null) {
        dateflag = 0;
        bothornot = "both";
    }


    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor == null && tlitergayevn == null) {
        dateflag = 0;
        bothornot = "not";
        milktype = "म्हैस";
    }

    // Remaining combinations

    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor == null && tlitergayevn == null) {
        //

    }

    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor != null && tlitergayevn == null) {
        dateflag = 0;
        bothornot = "both";
    }

    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor == null && tlitergayevn != null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor != null && tlitergayevn == null) {
        dateflag = 2;
        bothornot = "not";
        milktype = "गाय";

    }

    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor == null && tlitergayevn != null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor != null && tlitergayevn != null) {
        dateflag = 0;
        bothornot = "both";

    }

    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor == null && tlitergayevn != null) {
        dateflag = 2;
        bothornot = "not";
        milktype = "गाय";
    }

    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor != null && tlitergayevn != null) {
        dateflag = 2;
        bothornot = "not";
        milktype = "गाय";
    }

    return (
        <>
            <Page
                key={index}
                size={localStorage.getItem("dur") === "10" ? "A5" : "A4"}
                orientation={localStorage.getItem("dur") === "10" ? "landscape" : "portrait"}
                style={styles.page}
            >
                {isGenerating ? (
                    <View style={styles.loading}>
                        <Text style={styles.headtextsmall3}>बिल तयार होत आहे, {`अंदाजे वेळ: ${min} मिनिटे व ${sec} सेकंड्स`}</Text>
                        <Text style={styles.headtextsmall4}>एकूण बिल पाने : {pages} </Text>
                    </View>
                ) : (

                    <View>
                        <View style={styles.row1}>
                            {/* <Image style={styles.image} src={Logo} /> */}
                            <Text style={styles.headtextsmall}>{"कालावधी : " + formatDate(knoData[0].date1) + " ते " + formatDate(knoData[0].date2)}</Text>
                            <Text style={styles.headtextsmall2}>{knoData[0].kendra + "," + localStorage.getItem("city") + ",(मो." + localStorage.getItem('contact') + ")"}</Text>
                            <Text style={styles.headtextsmall}>{"सभासद खाते: " + kno + " (" + knoData[0].cname + ")"}</Text>
                        </View>
                        (
                        {
                            bothornot === "both" ?
                                <View>
                                    <View style={styles.row2}>
                                        <Text style={styles.headtextsmall}>{"म्हैस"}</Text>
                                        <Text style={styles.headtextsmall}>{"गाय"}</Text>
                                    </View>
                                    <View style={styles.row2}>
                                        <Text style={styles.headtextsmallleft1}>{"सकाळ"}</Text>
                                        <Text style={styles.headtextsmallleft2}>{"संध्याकाळ"}</Text>
                                        <Text style={styles.headtextsmallleft3}>{"सकाळ"}</Text>
                                        <Text style={styles.headtextsmallleft4}>{"संध्याकाळ"}</Text>
                                    </View>
                                </View>
                                :
                                <View>
                                    <View style={styles.row2single}>
                                        <Text style={styles.headtextsmall}>{milktype}</Text>
                                    </View>
                                    <View style={styles.row2both}>
                                        <Text style={styles.headtextsmallleft1big}>{"सकाळ"}</Text>
                                        <Text style={styles.headtextsmallleft2big}>{"संध्याकाळ"}</Text>
                                    </View>
                                </View>
                        }
                        )

                        <View style={styles.tableContainer}>
                            {
                                ['म्हैस-सकाळ', 'म्हैस-संध्याकाळ', 'गाय-सकाळ', 'गाय-संध्याकाळ'].map((typeTimeKey, tableIndex) => {
                                    const [type, time] = typeTimeKey.split('-'); // Split type and time
                                    const entries = groupByTypeAndTime[typeTimeKey];
                                    const tableDataWithZeros = getTableDataWithZeros(entries, knoData[0].date1, knoData[0].date2, type, time);

                                    // Calculate totals
                                    const totalLiter = tableDataWithZeros.reduce((total, entry) => total + parseFloat(entry.liter), 0);
                                    totalOfTotalLiter += totalLiter;


                                    const totalFat = tableDataWithZeros
                                        .filter(entry => entry.fat > 0) // Filter entries where fat is greater than 0
                                        .reduce((total, entry) => total + parseFloat(entry.fat), 0); // Sum up the fat values

                                    const totalEntriesWithFat = tableDataWithZeros
                                        .filter(entry => entry.fat > 0) // Filter entries where fat is greater than 0
                                        .length;

                                    const avgfat = totalFat / totalEntriesWithFat;


                                    const totalSNF = tableDataWithZeros
                                        .filter(entry => entry.fat > 0) // Filter entries where fat is greater than 0
                                        .reduce((total, entry) => total + parseFloat(entry.snf), 0); // Sum up the fat values

                                    const totalEntriesWithSNF = tableDataWithZeros
                                        .filter(entry => entry.snf > 0) // Filter entries where fat is greater than 0
                                        .length;

                                    const avgsnf = totalSNF / totalEntriesWithSNF;


                                    tkapat = parseFloat(knoData[0].cthev !== null && !isNaN(knoData[0].cthev) ? knoData[0].cthev : 0)
                                        + parseFloat(knoData[0].cthevitr1 !== null && !isNaN(knoData[0].cthevitr1) ? knoData[0].cthevitr1 : 0)
                                        + parseFloat(knoData[0].cthevitr2 !== null && !isNaN(knoData[0].cthevitr2) ? knoData[0].cthevitr2 : 0)
                                        + parseFloat(knoData[0].cthevitr3 !== null && !isNaN(knoData[0].cthevitr3) ? knoData[0].cthevitr3 : 0)
                                        + parseFloat(knoData[0].itrkapat1 !== null && !isNaN(knoData[0].itrkapat1) ? knoData[0].itrkapat1 : 0)
                                        + parseFloat(knoData[0].itrkapat2 !== null && !isNaN(knoData[0].itrkapat2) ? knoData[0].itrkapat2 : 0)
                                        + parseFloat(knoData[0].itrkapat3 !== null && !isNaN(knoData[0].itrkapat3) ? knoData[0].itrkapat3 : 0)
                                        + parseFloat(knoData[0].retan !== null && !isNaN(knoData[0].retan) ? knoData[0].retan : 0)
                                        + parseFloat(knoData[0].medi !== null && !isNaN(knoData[0].medi) ? knoData[0].medi : 0)
                                        + parseFloat(knoData[0].advkapat !== null && !isNaN(knoData[0].advkapat) ? knoData[0].advkapat : 0)
                                        + parseFloat(knoData[0].khadyakapat !== null && !isNaN(knoData[0].khadyakapat) ? knoData[0].khadyakapat : 0)
                                        + parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(knoData[0].ikapatliter) : 0) + parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0) //+ parseFloat(knoData[0].vah !== null && !isNaN(knoData[0].vah) ? (parseFloat(knoData[0].vah)) : 0)

                                    {
                                        localStorage.getItem("billtype") === "oldbill" ?
                                            tyene = parseFloat(knoData[0].newadv !== null && !isNaN(knoData[0].newadv) ? knoData[0].newadv : 0) + parseFloat(knoData[0].newkhadya !== null && !isNaN(knoData[0].newkhadya) ? knoData[0].newkhadya : 0)
                                            :
                                            tyene = '0';
                                    }

                                    ada = parseFloat(parseFloat(sumOfFifthTable) - parseFloat(tkapat) + parseFloat(knoData[0].vah !== null && !isNaN(knoData[0].vah) ? (parseFloat(knoData[0].vah)) : 0)).toFixed(2);

                                    const totalAmt = tableDataWithZeros.reduce((total, entry) => total + parseFloat(entry.amt), 0);

                                    dateflag = "";
                                    bothornot = "";

                                    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor != null && tlitergayevn != null) {
                                        dateflag = 0;
                                        bothornot = "both";

                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor == null && tlitergayevn == null) {
                                        dateflag = 0;
                                        bothornot = "not";
                                        if (type == 'म्हैस') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }

                                    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor == null && tlitergayevn == null) {
                                        dateflag = 0;
                                        bothornot = "not";
                                        if (type == 'म्हैस') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }

                                    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor != null && tlitergayevn == null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor != null && tlitergayevn != null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor != null && tlitergayevn != null) {
                                        dateflag = 2;
                                        bothornot = "not";
                                        if (type == 'गाय') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }

                                    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor != null && tlitergayevn == null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor != null && tlitermhevn != null && tlitergaymor == null && tlitergayevn != null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor == null && tlitergayevn == null) {
                                        dateflag = 0;
                                        bothornot = "not";
                                        if (type == 'म्हैस') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }

                                    // Remaining combinations

                                    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor == null && tlitergayevn == null) {
                                        //
                                    }

                                    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor != null && tlitergayevn == null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor == null && tlitergayevn != null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor != null && tlitergayevn == null) {
                                        dateflag = 2;
                                        bothornot = "not";
                                        if (type == 'गाय') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }

                                    if (tlitermhmor == null && tlitermhevn != null && tlitergaymor == null && tlitergayevn != null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor != null && tlitermhevn == null && tlitergaymor != null && tlitergayevn != null) {
                                        dateflag = 0;
                                        bothornot = "both";
                                        return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                    }

                                    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor == null && tlitergayevn != null) {
                                        dateflag = 2;
                                        bothornot = "not";
                                        if (type == 'गाय') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }

                                    if (tlitermhmor == null && tlitermhevn == null && tlitergaymor != null && tlitergayevn != null) {
                                        dateflag = 2;
                                        bothornot = "not";
                                        if (type == 'गाय') {
                                            return renderTable(tableDataWithZeros, tableIndex, totalLiter, avgfat, avgsnf, totalAmt, typeTimeKey, dateflag, bothornot);
                                        }
                                    }
                                })
                            }

                            <View style={styles.tableone}>
                                <View style={bothornot === "both" ? styles.tableHeaderone : styles.tableHeaderonebig}>
                                    <Text style={bothornot === "both" ? styles.tableCellone : styles.tableCellonebig}>{"रक्कम"}</Text>
                                </View>
                                {sumsOfAmtColumns.map((sum, entryIndex) => (
                                    <View key={entryIndex} style={bothornot === "both" ? styles.tableRowone : styles.tableRowonebig}>
                                        <Text style={bothornot === "both" ? styles.tableCellone : styles.tableCellonebigdata}>{sum.toFixed(2)}</Text>
                                    </View>
                                ))}

                                <View style={bothornot === "both" ? styles.allbill : styles.allbillbig}>
                                    <Text style={bothornot === "both" ? styles.tableCellone : styles.tableCellonebig2}>{sumOfFifthTable}</Text>
                                    {/* sumOfFifthTable */}
                                </View>
                            </View>
                        </View>

                        <View style={styles.line} />

                        <View style={styles.row}>
                            {
                                localStorage.getItem("design") === "1" ?
                                    <View>
                                        <View style={styles.table}>
                                            <View style={styles.tableRowacc}>
                                                <Text style={styles.tableHeadersmbigacc}>{"--"}</Text>
                                                <Text style={styles.tableHeadersmbigacc}>{"ठेव"}</Text>

                                                {localStorage.getItem("itr1name") != "-" ? <Text style={styles.tableHeadersmbigacc}>{localStorage.getItem("itr1name")}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") != "-" ? <Text style={styles.tableHeadersmbigacc}>{localStorage.getItem("itr2name")}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") != "-" ? <Text style={styles.tableHeadersmbigacc}>{localStorage.getItem("itr3name")}</Text> : <Text></Text>}

                                                <Text style={styles.tableHeadersmbigacc}>{"एड्व्हांस"}</Text>
                                                <Text style={styles.tableHeadersmbigacc}>{"खाद्य"}</Text>
                                                {localStorage.getItem("itr1name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}

                                            </View>
                                            <View style={styles.tableRowacc}>
                                                <Text style={styles.tableHeadersmbigacc}>{"मागील"}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].mthev}</Text>
                                                {localStorage.getItem("itr1name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].magilthevitr1}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].magilthevitr2}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].magilthevitr3}</Text> : <Text></Text>}
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].madv}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].mkhadya}</Text>

                                                {localStorage.getItem("itr1name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}

                                            </View>
                                            <View style={styles.tableRowacc}>
                                                <Text style={styles.tableHeadersmbigacc}>{"चालू"}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{"-"}</Text>
                                                {localStorage.getItem("itr1name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].cthevitr1}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].cthevitr2}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].cthevitr3}</Text> : <Text></Text>}
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].cadv}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].ckhadya}</Text>

                                                {localStorage.getItem("itr1name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}

                                            </View>
                                            <View style={styles.tableRowacc}>
                                                <Text style={styles.tableHeadersmbigacc}>{"कपात"}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].cthev}</Text>
                                                {localStorage.getItem("itr1name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{ }</Text> : <Text></Text>}
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].advkapat}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].khadyakapat}</Text>

                                                {localStorage.getItem("itr1name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}

                                            </View>
                                            <View style={styles.tableRowacc}>
                                                <Text style={styles.tableHeadersmbigacc}>{"शिल्लक"}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].newthev}</Text>
                                                {localStorage.getItem("itr1name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].newthevitr1}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].newthevitr2}</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") != "-" ? <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].newthevitr3}</Text> : <Text></Text>}
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].newadv}</Text>
                                                <Text style={styles.tableHeadersmbigaccwhite}>{knoData[0].newkhadya}</Text>

                                                {localStorage.getItem("itr1name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr2name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                                {localStorage.getItem("itr3name") == "-" ? <Text style={styles.tableHeadersmbigaccblank}>{ }</Text> : <Text></Text>}
                                            </View>
                                        </View>
                                    </View>
                                    :
                                    <>
                                        <View style={styles.column1}>
                                            {localStorage.getItem("billtype") === "oldbill" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"मागील बाकी :"}</Text>
                                                    <Text style={styles.column1Value}>{parseFloat(parseFloat(knoData[0].madv !== null && !isNaN(knoData[0].madv) ? parseFloat(knoData[0].madv).toFixed(2) : 0) + parseFloat(knoData[0].mkhadya !== null && !isNaN(knoData[0].mkhadya) ? knoData[0].mkhadya : 0)).toFixed(2)}</Text>
                                                </View>
                                                :
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"मागील बाकी :"}</Text>
                                                    <Text style={styles.column1Value}>{parseFloat(localStorage.getItem("bcount")) > 0 ? parseFloat(parseFloat(knoData[0].madv !== null && !isNaN(knoData[0].madv) ? parseFloat(knoData[0].madv).toFixed(2) : 0) + parseFloat(knoData[0].mkhadya !== null && !isNaN(knoData[0].mkhadya) ? knoData[0].mkhadya : 0)).toFixed(2) : knoData[0].obal !== null && !isNaN(knoData[0].obal) ? knoData[0].obal : 0}</Text>
                                                </View>
                                            }
                                            {localStorage.getItem("billtype") === "oldbill" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"खाद्य चालू :"}</Text>
                                                    <Text style={styles.column1Value}>{knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0}</Text>
                                                </View>
                                                :
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"खाद्य चालू N:"}</Text>
                                                    <Text style={styles.column1Value}>{knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0}</Text>
                                                </View>
                                            }
                                            {localStorage.getItem("billtype") === "oldbill" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"एड्व्हांस चालू :"}</Text>
                                                    <Text style={styles.column1Value}>{parseFloat(knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0).toFixed(2)}</Text>
                                                </View> :
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"एड्व्हांस चालू N:"}</Text>
                                                    <Text style={styles.column1Value}>{parseFloat(knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0).toFixed(2)}</Text>
                                                </View>
                                            }
                                        </View>

                                        <View style={styles.column1}>
                                            <View style={styles.column1Row}>
                                                <Text style={styles.column1Label}>{"एकूण बाकी :"}</Text>
                                                <Text style={styles.column1Value}>{parseFloat(parseFloat(parseFloat(knoData[0].madv !== null && !isNaN(knoData[0].madv) ? parseFloat(knoData[0].madv).toFixed(2) : 0) + parseFloat(knoData[0].mkhadya !== null && !isNaN(knoData[0].mkhadya) ? knoData[0].mkhadya : 0)) + parseFloat(knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0) + parseFloat(knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0)).toFixed(2)}</Text>
                                            </View>
                                            {localStorage.getItem("billtype") === "oldbill" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"प्रत्यक्ष कपात:"}</Text>
                                                    <Text style={styles.column1Value}>{parseFloat(parseFloat(knoData[0].advkapat !== null && !isNaN(knoData[0].advkapat) ? parseFloat(knoData[0].advkapat).toFixed(2) : 0) + parseFloat(knoData[0].khadyakapat !== null && !isNaN(knoData[0].khadyakapat) ? parseFloat(knoData[0].khadyakapat).toFixed(2) : 0)).toFixed(2)}</Text>
                                                </View>
                                                :
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"प्रत्यक्ष कपात:"}</Text>
                                                    <Text style={styles.column1Value}>{parseFloat(parseFloat(knoData[0].advkapat !== null && !isNaN(knoData[0].advkapat) ? parseFloat(knoData[0].advkapat).toFixed(2) : 0) + parseFloat(knoData[0].khadyakapat !== null && !isNaN(knoData[0].khadyakapat) ? parseFloat(knoData[0].khadyakapat).toFixed(2) : 0)).toFixed(2)}</Text>
                                                </View>
                                            }
                                            <View style={styles.column1Row}>
                                                <Text style={styles.column1Label}>{"येणे बाकी:"}</Text>
                                                <Text style={styles.column1Value}>{parseFloat(parseFloat(parseFloat(parseFloat(knoData[0].madv !== null && !isNaN(knoData[0].madv) ? parseFloat(knoData[0].madv).toFixed(2) : 0) + parseFloat(knoData[0].mkhadya !== null && !isNaN(knoData[0].mkhadya) ? knoData[0].mkhadya : 0)) + parseFloat(knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0) + parseFloat(knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0)) - parseFloat(parseFloat(knoData[0].advkapat !== null && !isNaN(knoData[0].advkapat) ? parseFloat(knoData[0].advkapat).toFixed(2) : 0) + parseFloat(knoData[0].khadyakapat !== null && !isNaN(knoData[0].khadyakapat) ? parseFloat(knoData[0].khadyakapat).toFixed(2) : 0))).toFixed(2)}</Text>
                                            </View>
                                        </View>
                                    </>
                            }


                            <>
                                {
                                    <View style={styles.column3}>
                                        <View style={styles.grayhead}>
                                            <Text style={styles.column1Labelgray}>{"इतर कपाती"}</Text>
                                        </View>
                                        <View style={styles.columnbottom}>
                                            {localStorage.getItem("itr4name") != "-" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{localStorage.getItem("itr4name")} : </Text>
                                                    <Text style={styles.column1Value}>{knoData[0].itrkapat1}</Text>
                                                </View> : ''}
                                            {localStorage.getItem("itr5name") != "-" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{localStorage.getItem("itr5name")} : </Text>
                                                    <Text style={styles.column1Value}>{knoData[0].itrkapat2}</Text>
                                                </View> : ''}
                                            {localStorage.getItem("itr6name") != "-" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{localStorage.getItem("itr6name")} : </Text>
                                                    <Text style={styles.column1Value}>{knoData[0].itrkapat3}</Text>
                                                </View> : ''}


                                            {localStorage.getItem("retan") == "चालू" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"रेतन विक्री"} : </Text>
                                                    <Text style={styles.column1Value}>{knoData[0].retan}</Text>
                                                </View>
                                                : ''}

                                            {localStorage.getItem("medi") == "चालू" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"औषध"} : </Text>
                                                    <Text style={styles.column1Value}>{knoData[0].medi}</Text>
                                                </View>
                                                : ''}
                                        </View>
                                    </View>


                                   
                                }
                            </>


                            <View style={styles.column3}>
                                <View style={styles.grayhead}>
                                    <Text style={styles.column1Labelgray}>{"एकूण"}</Text>
                                </View>
                                <View style={styles.columnbottom}>
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण लिटर:"}</Text>
                                        <Text style={styles.column1Value}>{totalOfTotalLiter.toFixed(2)}</Text>
                                    </View>
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण येणे:"}</Text>
                                        <Text style={styles.column1Value}>{tyene.toFixed(2)}</Text>
                                    </View>
                                    {
                                        localStorage.getItem("showother") === "Yes" ?
                                            <>

                                                {
                                                    parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(knoData[0].ikapatliter) : 0) > 0
                                                        ?
                                                        localStorage.getItem("billtype") === "oldbill" ?

                                                            <View style={styles.column1Row}>
                                                                <Text style={styles.column1Label}>{"इ.कपात/लि :"} </Text>
                                                                <Text style={styles.column1Value}>{knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(knoData[0].ikapatliter) : 0}</Text>
                                                            </View>
                                                            :
                                                            <View style={styles.column1Row}>
                                                                <Text style={styles.column1Label}>{"इ.कपात/लि N:"} </Text>
                                                                <Text style={styles.column1Value}>{knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(parseFloat(knoData[0].ikapatliter) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0}</Text>
                                                            </View>

                                                        : ''

                                                }
                                                {
                                                    parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill) : 0) > 0
                                                        ?
                                                        <View style={styles.column1Row}>
                                                            <Text style={styles.column1Label}>{"इतर कपात \n" + localStorage.getItem("ikapatnote")}</Text>
                                                            <Text style={styles.column1Value}>{knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0}</Text>
                                                        </View>
                                                        : ''
                                                }

                                            </>
                                            : ""
                                    }
                                </View>


                            </View>

                            <View style={styles.column3}>
                                <View style={styles.grayhead}>
                                    <Text style={styles.column1Labelgray}>{"बील"}</Text>
                                </View>
                                <View style={styles.columnbottom}>
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण:"}</Text>
                                        <Text style={styles.column1Value}>{sumOfFifthTable}</Text>
                                    </View>
                                    {
                                        parseFloat(knoData[0].vah !== null && !isNaN(knoData[0].vah) ? parseFloat(knoData[0].vah) : 0) > 0
                                            ?
                                            localStorage.getItem("billtype") === "oldbill" ?
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"वाहतूक:"}</Text>
                                                    <Text style={styles.column1Value}>{knoData[0].vah !== null && !isNaN(knoData[0].vah) ? (parseFloat(knoData[0].vah).toFixed(2)) : 0}</Text>
                                                </View>
                                                :
                                                <View style={styles.column1Row}>
                                                    <Text style={styles.column1Label}>{"वाहतूक:"}</Text>
                                                    <Text style={styles.column1Value}>{knoData[0].mhvah !== null && !isNaN(knoData[0].mhvah) ? parseFloat(parseFloat(knoData[0].mhvah) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0}</Text>
                                                </View>
                                            : ''
                                    }
                                    {localStorage.getItem("billtype") === "oldbill" ?
                                        <View style={styles.column1Row}>
                                            <Text style={styles.column1Label}>{"ए.कपात :"}</Text>
                                            <Text style={styles.column1Value}>{parseFloat(tkapat).toFixed(2)}</Text>
                                        </View>
                                        :
                                        <View style={styles.column1Row}>
                                            <Text style={styles.column1Label}>{"ए.कपात:"}</Text>
                                            <Text style={styles.column1Value}>{parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev) + parseFloat(kapat[kno] && !isNaN(kapat[kno].cuchal) ? kapat[kno].cuchal : 0) + parseFloat(kapat[kno] && !isNaN(kapat[kno].ckhadya) ? kapat[kno].ckhadya : 0) + parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(parseFloat(knoData[0].ikapatliter) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0) + parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0) + parseFloat(knoData[0].mhvah !== null && !isNaN(knoData[0].mhvah) ? parseFloat(parseFloat(knoData[0].mhvah) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0)).toFixed(2)}</Text>
                                        </View>
                                    }

                                </View>

                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.grayhead}>
                                        <Text style={styles.column1Labelgreen}>{"अदा  : ₹"}</Text>
                                        <Text style={parseFloat(ada) >= 0 ? styles.column1Labelgreen : styles.column1Labelgreen}>{parseFloat(ada).toFixed(0)}</Text>
                                    </View>
                                    :
                                    <View style={styles.grayhead}>
                                        <Text style={styles.column1Labelgray}>{"अदा  : ₹"}</Text>
                                        <Text style={parseFloat(ada) >= 0 ? styles.column1Labelgray : styles.column1Labelgray}>{parseFloat(parseFloat(sumOfFifthTable) - parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev) + parseFloat(kapat[kno] && !isNaN(kapat[kno].cuchal) ? kapat[kno].cuchal : 0) + parseFloat(kapat[kno] && !isNaN(kapat[kno].ckhadya) ? kapat[kno].ckhadya : 0) + parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(parseFloat(knoData[0].ikapatliter) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0) + parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0) + parseFloat(knoData[0].mhvah !== null && !isNaN(knoData[0].mhvah) ? parseFloat(parseFloat(knoData[0].mhvah) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0)).toFixed(2)).toFixed(2)}</Text>
                                    </View>
                                }
                            </View>
                        </View>
                        <View style={styles.line} />
                        <Image style={styles.image} src={Logo} />
                        {/* <View style={styles.imagerow}>
                            <View style={styles.simplerow}>


                            </View>
                            <Text style={styles.imgtext}>{"सॉफ्टवेअर खरेदीसाठी संपर्क : 800-788-9200"}</Text>
                        </View> */}

                        {index < Object.keys(data).length - 1 && <Text style={styles.pageBreak} />}

                    </View>)}
            </Page>

        </>
    );

}

export default Billprint;

import React, { useEffect, useState, useMemo } from 'react';
import './Profitlossprint.css';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";

Font.register({
    family: 'Lato-Italic',
    src: LatoItalicFont, // Use the imported font variable
});



const Profitlossprint = (props) => {

    const [customerData, setCustomerData] = useState([]);
    const [literTotal, setLiterTotal] = useState(0);
    const [amtTotal, setAmtTotal] = useState(0);
    let totals = {};

    const sourceData = customerData;
    const posDict = groupByHead(sourceData, true);
    const negDict = groupByHead(sourceData, false);
    const posGroups = toGroupArray(posDict);
    const negGroups = toGroupArray(negDict);

    // Calculate total number of rows on each side (group header row + records)
    const posRows = posGroups.reduce((sum, group) => sum + group.records.length + 1, 0);
    const negRows = negGroups.reduce((sum, group) => sum + group.records.length + 1, 0);
    const maxRows = Math.max(posRows, negRows);

    // Add blank rows so that both sides have the same number of rows
    const posGroupsFilled = addBlankRows([...posGroups], maxRows);
    const negGroupsFilled = addBlankRows([...negGroups], maxRows);

    const grandPosTotal = sourceData.reduce(
        (sum, row) => sum + parseFloat(row.positive_amount || 0),
        0
    );
    const grandNegTotal = sourceData.reduce(
        (sum, row) => sum + parseFloat(row.negative_amount || 0),
        0
    );

    useEffect(() => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            date1: localStorage.getItem("date1print"),
            date2: localStorage.getItem("date2print"),
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getprofitandloss4.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);
            setCustomerData(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const getDatesInRange = (date1, date2) => {
        const dates = [];
        const currentDate = new Date(date1);

        while (currentDate <= new Date(date2)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(inputDate).toLocaleDateString('en-US', options);
    }

    if (Array.isArray(customerData) && customerData.length > 0) {
        totals = {
            positive_opening_balance: customerData.reduce(
                (total, rowData) =>
                    total + parseFloat(rowData.positive_opening_balance ? parseFloat(rowData.positive_opening_balance).toFixed(2) : 0),
                0
            ),
            positive_amount: customerData.reduce(
                (total, rowData) =>
                    total + parseFloat(rowData.positive_amount ? parseFloat(rowData.positive_amount).toFixed(2) : 0),
                0
            ),
            negative_opening_balance: customerData.reduce(
                (total, rowData) =>
                    total + parseFloat(rowData.negative_opening_balance ? parseFloat(rowData.negative_opening_balance).toFixed(2) : parseFloat(rowData.negative_opening_balance).toFixed(2)),
                0
            ),
            negative_amount: customerData.reduce(
                (total, rowData) =>
                    total + parseFloat(rowData.negative_amount ? parseFloat(parseFloat(rowData.negative_amount)).toFixed(2) : parseFloat(rowData.negative_amount).toFixed(2)),
                0
            ),
        };

    }


    function groupByHead(data, isPositive = true) {
        return data.reduce((acc, row) => {
            const head = isPositive ? row.positive_head : row.negative_head;
            if (!head) return acc;
            if (!acc[head]) {
                acc[head] = { total: 0, records: [] };
            }
            const amt = isPositive
                ? parseFloat(row.positive_amount || 0)
                : parseFloat(row.negative_amount || 0);
            acc[head].total += amt;
            acc[head].records.push(row);
            return acc;
        }, {});
    }

    function toGroupArray(groupedObj) {
        return Object.entries(groupedObj).map(([head, info]) => ({
            head,
            total: info.total,
            records: info.records,
        }));
    }

    // Function to add blank rows so both sides have the same total number of rows.
    function addBlankRows(groups, totalRows) {
        let currentRows = groups.reduce((sum, group) => sum + group.records.length + 1, 0); // +1 for group header
        let blanksNeeded = totalRows - currentRows;
        if (blanksNeeded > 0) {
            groups.push({
                head: "",
                total: 0,
                records: new Array(blanksNeeded).fill({}),
            });
        }
        return groups;
    }


    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <PDFViewer className="pdf">
            <Document>
                {customerData.length > 0 && (
                    <Page size="A4" orientation='landscape'>
                        {/* Header */}
                        <Text style={styles.latoText}>{localStorage.getItem("kendra")}</Text>
                        <Text style={styles.latoText2}>{"नफा-तोटा पत्रक"}</Text>
                        <Text style={styles.latoText2}>
                            {"कालावधी : " + formatDate(localStorage.getItem("date1print")) + " ते " + formatDate(localStorage.getItem("date2print"))}
                        </Text>


                        {/* Table */}
                        <View style={{ flexDirection: 'row' }}>
                            {/* Positive Side Table */}
                            <View style={{ width: '50%', paddingRight: 5}}>
                                <View style={styles.table}>
                                    {/* Table Header */}
                                    <View style={styles.tableRow3}>
                                        <Text style={styles.tableHeader}>नफा तोटा जमा बाजू</Text>
                                        <Text style={styles.tableHeadersmall}>पोट रक्कम</Text>
                                        <Text style={styles.tableHeadersmallr0}>रक्कम</Text>
                                    </View>
                                    {posGroupsFilled.map((group, gIndex) => (
                                        <React.Fragment key={`pos-group-${gIndex}`}>
                                            {group.head && (
                                                <View style={[styles.tableRow, styles.tableRowHead]}>
                                                    <Text style={styles.tabletd} colSpan="2">
                                                        {group.head}
                                                    </Text>
                                                    <Text style={styles.tabletdsmall}>
                                                        {group.total > 0 ? group.total.toFixed(2) : ""}
                                                    </Text>
                                                </View>
                                            )}
                                            {group.records.map((rec, rIndex) => (
                                                <View key={`pos-group-${gIndex}-row-${rIndex}`} style={styles.tableRow}>
                                                    <Text style={styles.tabletd2}>
                                                        {rec.positive_kno
                                                            ? `${rec.positive_kno} : ${rec.positive_name}`
                                                            : "-"}
                                                    </Text>
                                                    <Text style={styles.tableCell}>
                                                        {rec.positive_amount
                                                            ? parseFloat(rec.positive_amount).toFixed(2)
                                                            : ""}
                                                    </Text>
                                                    <Text style={styles.tableCell}>{""}</Text>
                                                </View>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                    <View style={styles.tableRow3}>
                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                        <Text style={styles.tableHeadersmall}>{"-"}</Text>
                                        <Text style={styles.tableHeadersmallr0}>
                                            {grandPosTotal.toFixed(2)}
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            {/* Negative Side Table */}
                            <View style={{ width: '50%', paddingLeft:2 }}>
                                <View style={styles.table}>
                                    {/* Table Header */}
                                    <View style={styles.tableRow3}>
                                        <Text style={styles.tableHeader}>नफा तोटा नावे बाजू</Text>
                                        <Text style={styles.tableHeadersmall}>पोट रक्कम</Text>
                                        <Text style={styles.tableHeadersmallr0}>रक्कम</Text>
                                    </View>
                                    {negGroupsFilled.map((group, gIndex) => (
                                        <React.Fragment key={`neg-group-${gIndex}`}>
                                            {group.head && (
                                                <View style={[styles.tableRow, styles.tableRowHead]}>
                                                    <Text style={styles.tabletd} colSpan="2">
                                                        {group.head}
                                                    </Text>
                                                    <Text style={styles.tabletdsmall}>
                                                        {group.total > 0 ? group.total.toFixed(2) : ""}
                                                    </Text>
                                                </View>
                                            )}
                                            {group.records.map((rec, rIndex) => (
                                                <View key={`neg-group-${gIndex}-row-${rIndex}`} style={styles.tableRow}>
                                                    <Text style={styles.tabletd2}>
                                                        {rec.negative_kno
                                                            ? `${rec.negative_kno} : ${rec.negative_name}`
                                                            : "-"}
                                                    </Text>
                                                    <Text style={styles.tableCell}>
                                                        {rec.negative_amount
                                                            ? parseFloat(rec.negative_amount).toFixed(2)
                                                            : ""}
                                                    </Text>
                                                    <Text style={styles.tableCell}>{""}</Text>
                                                </View>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                    <View style={styles.tableRow3}>
                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                        <Text style={styles.tableHeadersmall}>{"-"}</Text>
                                        <Text style={styles.tableHeadersmallr0}>
                                            {grandNegTotal.toFixed(2)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Page>
                )}
            </Document>
        </PDFViewer>
    );

};

// ... (styles and export)



const styles = StyleSheet.create({
    page: {
        paddingTop: 10,
        flexDirection: 'row',
        justifyContent: 'center', // Center horizontally
    },
    header: {
        position: 'absolute',
        top: 0, // Adjust this value to change the vertical position
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
    },
    table: {
        display: 'table',
        width: '97%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableone: {
        display: 'table',
        width: '18%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableCellone: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        fontSize: 8,
        fontFamily: 'Lato-Italic',
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },
    tableRowone: {
        flexDirection: 'row',
        width: '100%',
    },
    tableHeaderone: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        border: '1px solid #000',
        padding: 2,
        fontSize: 8,
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },

    tableRow: {
        flexDirection: 'row',

    },
    tableRow2: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
    },

    tableRow3: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 10,
    },
    line: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
        height: 1,
        width: '100vw',
        marginBottom: 5,
    },



    tableHeadersmalltd: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderStyle: 'solid',
        padding: 2,
        border: '0.5px solid lightgray',
        textAlign: 'center',
        fontSize: 10,
        display: 'flex',
        width: 90 // Remove quotes
    },

    tableHeadersmalltdbold: {
        fontFamily: 'Lato-Bold',
        fontWeight: 'bolder',
        borderStyle: 'solid',
        padding: 2,
        border: '0.5px solid lightgray',
        textAlign: 'center',
        fontSize: 10,
        display: 'flex',
        width: 90 // Remove quotes
    },

    tableHeader: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 0,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        padding: 2,
        borderColor: 'lightblack',
        textAlign: 'center',
        fontSize: 11,
        display: 'flex',
        width: 300 // Remove quotes
    },

    tableHeadersmall: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 0,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        padding: 2,
        borderColor: 'lightblack',
        textAlign: 'center',
        fontSize: 11,
        display: 'flex',
        width: 100 // Remove quotes
    },

    tableHeadersmallr0: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 1,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        padding: 2,
        borderColor: 'lightblack',
        textAlign: 'center',
        fontSize: 11,
        display: 'flex',
        width: 100 // Remove quotes
    },


    tabletd: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderStyle: 'solid', // Set the border style to 'solid'
        borderTopWidth: 0.5,    // Add border only for top side
        borderBottomWidth: 0.5, // Add border only for bottom side
        borderLeftWidth: 0.5,   // Add border only for left side
        borderRightWidth: 0.3,  // No border for right side
        borderColor: 'lightgray',
        padding: 2,
        textAlign: 'left',
        paddingLeft: 5,
        fontSize: 11,
        backgroundColor: 'rgb(240, 240, 240)',
        display: 'flex',
        width: 400 // Remove quotes
    },

    tabletdsmall: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderStyle: 'solid', // Set the border style to 'solid'
        borderTopWidth: 0.5,    // Add border only for top side
        borderBottomWidth: 0.5, // Add border only for bottom side
        borderLeftWidth: 0.5,   // Add border only for left side
        borderRightWidth: 0.3,  // No border for right side
        borderColor: 'lightgray',
        padding: 2,
        textAlign: 'center',
        paddingLeft: 5,
        fontSize: 11,
        backgroundColor: 'rgb(240, 240, 240)',
        display: 'flex',
        width: 100 // Remove quotes
    },

    tabletd2: {
        border: '0.5px solid lightgray',
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderStyle: 'solid', // Set the border style to 'solid'
        borderTopWidth: 0.5,    // Add border only for top side
        borderBottomWidth: 0.5, // Add border only for bottom side
        borderLeftWidth: 0.5,   // Add border only for left side
        borderRightWidth: 0.3,  // No border for right side
        borderColor: 'lightgray',
        padding: 2,
        textAlign: 'right',
        paddingRight: 5,
        fontSize: 11,
        display: 'flex',
        width: 242 // Remove quotes
    },

    tableHeaderlast: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 1,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        borderColor: 'lightblack',
        padding: 2,
        flex: 0.5,
        textAlign: 'center',
        fontSize: 9,
    },
    tableCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontFamily: 'Lato-Italic',
        width: 2,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    tableCellleft: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 11,
        color: 'black',
        fontFamily: 'Lato-Italic',
        width: 2,
        textAlign: 'left',
        fontWeight: 'bold'
    },
    tableCellgreen: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'green',
        width: 2,
        textAlign: 'center',
    },
    tableCellred: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 9,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'red',
        width: 2,
        textAlign: 'center',
    },
    latoTextlarge: {
        fontFamily: 'Lato-Italic',
        fontSize: 15,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText: {
        fontFamily: 'Lato-Italic',
        fontSize: 14,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText2: {
        fontFamily: 'Lato-Italic',
        fontSize: 11,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
    },
    headtextsmall: {
        fontFamily: 'Lato-Italic',
        fontSize: 10,
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 140,
        paddingRight: 140,
        backgroundColor: 'lightgray',
    },
    column1: {
        display: 'flex',
        flexDirection: 'column',
        width: '19%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column2: {
        display: 'flex',
        flexDirection: 'column',
        width: '15%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,


    },
    column3: {
        display: 'flex',
        flexDirection: 'column',
        width: '17%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column1Row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    column1Rowgray: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 6,
        marginTop: 5,
        border: '0.5px solid black',
        backgroundColor: 'lightgray'
    },
    column1Label: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'left', // Align labels to the left
    },
    column1Value: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'right', // Align values to the right
    },
    pageBreak: {
        pageBreakBefore: 'always',
    },
    totalsRow: {
        flexDirection: 'row',
        borderTop: '1px solid #000',
        paddingTop: 5,
        paddingLeft: 2,
    },
    totalsCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 8,
        width: 2,
        textAlign: 'center',
    },
    imagerow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 80, // Adjust the width as needed
        height: "auto", // Maintain aspect ratio
        marginLeft: -15,
        marginTop: 5

    },
    imgtext: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center', // Align labels to the left
    },
});

export default Profitlossprint

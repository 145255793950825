import React, { useEffect, useRef, useState } from 'react'
import './Kapatmaster.css'
import back from "../../imgs/backexam.png";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';

const Kapatmaster = (props) => {
    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ itr1name: "", itr1type: "", itr1value: "", itr2name: "", itr2type: "", itr2value: "", itr3name: "", itr3type: "", itr3value: "", itr4name: "", itr4type: "", itr4value: "", itr5name: "", itr5type: "", itr5value: "", itr6name: "", itr6type: "", itr6value: ""});
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');
    const [wait, setWait] = useState(false);
    const [retan, setRetan] = useState('बंद');
    const [medi, setMedi] = useState('बंद');

    const handleToggleretan = () => {
        setRetan(retan === 'चालू' ? 'बंद' : 'चालू');
    };

    const handleTogglemedi = () => {
        setMedi(medi === 'चालू' ? 'बंद' : 'चालू');
    };

    function handleEnterKey(event, currentIndex) {
        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));
    }

    const updatekapat = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.itr1name === "" || input.itr1type === "" || input.itr1value === "" || input.itr2name === "" || input.itr2type === "" || input.itr2value === "" || input.itr3name === "" || input.itr3type === "" || input.itr3value === "" || input.itr4name === "" || input.itr4type === "" || input.itr4value === "" || input.itr5name === "" || input.itr5type === "" || input.itr5value === "" || input.itr6name === "" || input.itr6type === "" || input.itr6value === "") {
            notifyerror("अपूर्ण एंट्री माहिती!");
            return;
        }
        let rawData = {
            itr1name: input.itr1name,
            itr1type: input.itr1type,
            itr1value: input.itr1value,
            itr2name: input.itr2name,
            itr2type: input.itr2type,
            itr2value: input.itr2value,
            itr3name: input.itr3name,
            itr3type: input.itr3type,
            itr3value: input.itr3value,
            itr4name: input.itr4name,
            itr4type: input.itr4type,
            itr4value: input.itr4value,
            itr5name: input.itr5name,
            itr5type: input.itr5type,
            itr5value: input.itr5value,
            itr6name: input.itr6name,
            itr6type: input.itr6type,
            itr6value: input.itr6value,
            retan: retan,
            medi: medi,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/updatekapatextra.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            if (response.data.trim() === "updated.") {
                notify("माहिती अपडेट झाली!")


                localStorage.setItem("itr1name", input.itr1name);
                localStorage.setItem("itr1type", input.itr1type);
                localStorage.setItem("itr1value", input.itr1value);
                localStorage.setItem("itr2name", input.itr2name);
                localStorage.setItem("itr2type", input.itr2type);
                localStorage.setItem("itr2value", input.itr2value);
                localStorage.setItem("itr3name", input.itr3name);
                localStorage.setItem("itr3type", input.itr3type);
                localStorage.setItem("itr3value", input.itr3value);
                localStorage.setItem("itr4name", input.itr4name);
                localStorage.setItem("itr4type", input.itr4type);
                localStorage.setItem("itr4value", input.itr4value);
                localStorage.setItem("itr5name", input.itr5name);
                localStorage.setItem("itr5type", input.itr5type);
                localStorage.setItem("itr5value", input.itr5value);
                localStorage.setItem("itr6name", input.itr6name);
                localStorage.setItem("itr6type", input.itr6type);
                localStorage.setItem("itr6value", input.itr6value);
                localStorage.setItem("retan", retan);
                localStorage.setItem("medi", medi);
            }
            else {
                notifyerror("पुन्हा प्रयत्न करा!")
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
        setInput(value => ({
            ...value,
            itr1name: localStorage.getItem("itr1name"), itr1type: localStorage.getItem("itr1type"), itr1value: localStorage.getItem("itr1value"),
            itr2name: localStorage.getItem("itr2name"), itr2type: localStorage.getItem("itr2type"), itr2value: localStorage.getItem("itr2value"),
            itr3name: localStorage.getItem("itr3name"), itr3type: localStorage.getItem("itr3type"), itr3value: localStorage.getItem("itr3value"),
            itr4name: localStorage.getItem("itr4name"), itr4type: localStorage.getItem("itr4type"), itr4value: localStorage.getItem("itr4value"),
            itr5name: localStorage.getItem("itr5name"), itr5type: localStorage.getItem("itr5type"), itr5value: localStorage.getItem("itr5value"),
            itr6name: localStorage.getItem("itr6name"), itr6type: localStorage.getItem("itr6type"), itr6value: localStorage.getItem("itr6value"),
           
        }))
        setRetan(localStorage.getItem("retan"));
        setMedi(localStorage.getItem("medi"));

    }, [])

    useEffect(()=>{
        console.log(input);
    },[input])


    async function getsangh() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkapatmaster.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (kno, pname, type, cuttype, value) => {
        setInput({ kno: kno, kapatname: pname, type: type, cuttype: cuttype, value: value })
        setNewedit("edit");
        setSredit(kno);
    }


    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <div>
            <div className="ratemain2">
                <div className="firstrowrepair">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <span>सभासद बिलामधील कपाती आपण इथून वाढवू शकता. </span>
                </div>

                <div className="firstrowrepair">
                    <span className='title'>ठेव क्रमांक - 1</span>
                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="text" className='form-control' autoComplete='off' id="itr1name" name="itr1name" value={input.itr1name} onChange={handleChange} placeholder='कपात नाव'></input>
                    <div className="firstrowrepair">
                        <select onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} className="form-control" autoComplete='off' id="itr1type" name="itr1type" value={input.itr1type} onChange={handleChange}>
                            <option key={0} value="liter">लिटरनुसार</option>
                            <option key={1} value="per">शेकड्यानुसार</option>
                        </select>
                    </div>
                    <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className='form-control' autoComplete='off' id="itr1value" name="itr1value" value={input.itr1value} onChange={handleChange} placeholder='रक्कम'></input>
                </div>

                <div className="firstrowrepair">
                    <span className='title'>ठेव क्रमांक - 2</span>
                    <input onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className='form-control' autoComplete='off' id="itr2name" name="itr2name" value={input.itr2name} onChange={handleChange} placeholder='कपात नाव'></input>
                    <div className="firstrowrepair">
                        <select onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} className="form-control" autoComplete='off' id="itr2type" name="itr2type" value={input.itr2type} onChange={handleChange}>
                            <option key={0} value="liter">लिटरनुसार</option>
                            <option key={1} value="per">शेकड्यानुसार</option>
                        </select>
                    </div>
                    <input onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} type="text" className='form-control' autoComplete='off' id="itr2value" name="itr2value" value={input.itr2value} onChange={handleChange} placeholder='रक्कम'></input>
                </div>

                <div className="firstrowrepair">
                    <span className='title'>ठेव क्रमांक - 3</span>
                    <input onKeyDown={e => handleEnterKey(e, 6)} ref={ref => (inputRefs.current[6] = ref)} type="text" className='form-control' autoComplete='off' id="itr3name" name="itr3name" value={input.itr3name} onChange={handleChange} placeholder='कपात नाव'></input>
                    <div className="firstrowrepair">
                        <select onKeyDown={e => handleEnterKey(e, 7)} ref={ref => (inputRefs.current[7] = ref)} className="form-control" autoComplete='off' id="itr3type" name="itr3type" value={input.itr3type} onChange={handleChange}>
                            <option key={0} value="liter">लिटरनुसार</option>
                            <option key={1} value="per">शेकड्यानुसार</option>
                        </select>
                    </div>
                    <input onKeyDown={e => handleEnterKey(e, 8)} ref={ref => (inputRefs.current[8] = ref)} type="text" className='form-control' autoComplete='off' id="itr3value" name="itr3value" value={input.itr3value} onChange={handleChange} placeholder='रक्कम'></input>
                </div>

                <div className="firstrowrepair">
                    <span className='title'>कपात क्रमांक - 1</span>
                    <input onKeyDown={e => handleEnterKey(e, 9)} ref={ref => (inputRefs.current[9] = ref)} type="text" className='form-control' autoComplete='off' id="itr4name" name="itr4name" value={input.itr4name} onChange={handleChange} placeholder='कपात नाव'></input>
                    <div className="firstrowrepair">
                        <select onKeyDown={e => handleEnterKey(e, 10)} ref={ref => (inputRefs.current[10] = ref)} className="form-control" autoComplete='off' id="itr4type" name="itr4type" value={input.itr4type} onChange={handleChange}>
                            <option key={0} value="liter">लिटरनुसार</option>
                            <option key={1} value="per">शेकड्यानुसार</option>
                        </select>
                    </div>
                    <input onKeyDown={e => handleEnterKey(e, 11)} ref={ref => (inputRefs.current[11] = ref)} type="text" className='form-control' autoComplete='off' id="itr4value" name="itr4value" value={input.itr4value} onChange={handleChange} placeholder='रक्कम'></input>
                </div>

                <div className="firstrowrepair">
                    <span className='title'>कपात क्रमांक - 2</span>
                    <input onKeyDown={e => handleEnterKey(e, 12)} ref={ref => (inputRefs.current[12] = ref)} type="text" className='form-control' autoComplete='off' id="itr5name" name="itr5name" value={input.itr5name} onChange={handleChange} placeholder='कपात नाव'></input>
                    <div className="firstrowrepair">
                        <select onKeyDown={e => handleEnterKey(e, 13)} ref={ref => (inputRefs.current[13] = ref)} className="form-control" autoComplete='off' id="itr5type" name="itr5type" value={input.itr5type} onChange={handleChange}>
                            <option key={0} value="liter">लिटरनुसार</option>
                            <option key={1} value="per">शेकड्यानुसार</option>
                        </select>
                    </div>
                    <input onKeyDown={e => handleEnterKey(e, 14)} ref={ref => (inputRefs.current[14] = ref)} type="text" className='form-control' autoComplete='off' id="itr5value" name="itr5value" value={input.itr5value} onChange={handleChange} placeholder='रक्कम'></input>
                </div>

                <div className="firstrowrepair">
                    <span className='title'>कपात क्रमांक - 3</span>
                    <input onKeyDown={e => handleEnterKey(e, 15)} ref={ref => (inputRefs.current[15] = ref)} type="text" className='form-control' autoComplete='off' id="itr6name" name="itr6name" value={input.itr6name} onChange={handleChange} placeholder='कपात नाव'></input>
                    <div className="firstrowrepair">
                        <select onKeyDown={e => handleEnterKey(e, 16)} ref={ref => (inputRefs.current[16] = ref)} className="form-control" autoComplete='off' id="itr6type" name="itr6type" value={input.itr6type} onChange={handleChange}>
                            <option key={0} value="liter">लिटरनुसार</option>
                            <option key={1} value="per">शेकड्यानुसार</option>
                        </select>
                    </div>
                    <input onKeyDown={e => handleEnterKey(e, 17)} ref={ref => (inputRefs.current[17] = ref)} type="text" className='form-control' autoComplete='off' id="itr6value" name="itr6value" value={input.itr6value} onChange={handleChange} placeholder='रक्कम'></input>
                </div>
                <div className='linekapat'></div>

                <div className="firstrowrepair">
                    <span className='title'>सेटिंग्ज</span>
                    <div className="autobox">
                        <span className='subheadmobileuser2'>कृत्रिम रेतन</span>
                        <label className="switch">
                            <input type="checkbox" checked={retan === 'चालू'} onChange={handleToggleretan} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className="autobox">
                        <span className='subheadmobileuser2'>पशुवैद्यकीय</span>
                        <label className="switch">
                            <input type="checkbox" checked={medi === 'चालू'} onChange={handleTogglemedi} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                <div id='savediv' className="firstrowrepair">
                    {
                        wait ?
                            <button onKeyDown={e => handleEnterKey(e, 18)} ref={ref => (inputRefs.current[18] = ref)} type='button' id='saverepair' className="btn btn-primary">लोडिंग...</button>
                            :
                            <button onKeyDown={e => handleEnterKey(e, 18)} ref={ref => (inputRefs.current[18] = ref)} type='button' id='save' onClick={updatekapat} className="btn btn-primary">सेट करा</button>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Kapatmaster

import React, { useContext, useEffect, useRef, useState } from 'react'
import './Newcustomer.css'
import { useLocation, useNavigate } from 'react-router-dom';
import back from "../../imgs/backexam.png";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import Globalcontext from '../../Context/Globalcontext';


const NewCustomer = (props) => {
    const ref = useRef(null);
    const ref2 = useRef(null);
    const refclear = useRef(null);
    const reffileclear = useRef(null);
    const inputRefs = useRef([]);
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const refblank = useRef(null);
    const refblankclose = useRef(null);
    const [showreport, setShowreport] = useState(false);
    const [page, setPage] = useState("first");
    const [showModal, setShowModal] = useState(false);
    const [imageurl, setImageUrl] = useState();
    const [input, setInput] = useState({ kno: "", sr: "", cname: "", enname: "", type: "", mobno: "", address: "", pass: "Auto", accno: "", obal: "", othev: "", mhvah: "", gayvah: "", mhthev: "", gaythev: "", kapatbill: "", hptabill: "", ikapatliter: "", ikapatbill: "", bname: "", aadhar: "", farmerid: "", vibhag: "", tag: "", branch: "", accno2: "0", mobileuser: "", enname: "", milkonoff: "चालू", msgonoff: "चालू", othev1: "", othev2: "", othev3: "", class: "ब वर्ग", knofrom: "", knoto: "",oretanmedi: "" });
    const [kno, setKno] = useState({ kno: "" });
    const [mastermenu, setMastermenu] = useState(true);
    const [newedit, setNewedit] = useState("new");
    const [value, setValue] = useState('');
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [type, setType] = useState({});
    const [contactdelete, setContactdelete] = useState({});
    const [bankname, setBankname] = useState([]);
    const [mobileusers, setMobileusers] = useState([]);
    const { newcustsource, setNewcustsource, sanksource, setSanksource, setHeadname, custlist, setCustlist } = useContext(Globalcontext);
    const [wait, setWait] = useState(false);
    const [className, setClassName] = useState("");

    const navigate = useNavigate();

    const handleBack = () => {
        if (page === "first") {
            props.visibility();
            if (newcustsource === "sank") {
                navigate("/Transections");
                setSanksource("custback");
                setNewcustsource("");
            }
        }
        else {
            setPage("first");
        }
    };

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);

    const clearAll = () => {
        setInput({ kno: "", sr: "", cname: "", type: "", mobno: "", address: "", pass: "Auto", accno: "", obal: "", othev: "", mhvah: "", gayvah: "", mhthev: "", gaythev: "", kapatbill: "", hptabill: "", ikapatliter: "", ikapatbill: "" });
    }

    useEffect(() => {

        if (localStorage.getItem("glcustsr") !== "") {
            setKno({ kno: localStorage.getItem("glcustsr") })
            getcustdata(localStorage.getItem("glcustsr"));
        }
        else {
            getmaxno();
            getsettings();
        }

        getbankname();
        getmobileusers();
        if (inputRefs.current[1]) {
            inputRefs.current[1].focus();
        }
    }, [])

    async function getbankname() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getbankname.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {


            if (response.data) {

                setBankname(response.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    async function getmobileusers() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getmobileusers.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setMobileusers(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    // useEffect(() => {
    //     console.log(bankname);
    // }, [bankname])

    const handlepage = () => {
        setPage("second");
    }

    async function getsettings() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getsettings.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                response.data.map((user, index) =>
                (
                    setInput(value => ({ ...value, mhvah: user.mhvah, gayvah: user.gayvah, mhthev: user.mhthev, gaythev: user.gaythev, kapatbill: 0, hptabill: 0, ikapatliter: user.ikapatliter, ikapatbill: user.ikapatbill }))
                ))
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("class", value);
            const url = "/custlistprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
        }
    }

    async function getcustdata(kno) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {

                response.data.map((user, index) =>
                (
                    setInput({ sr: user.sr, cname: user.cname, enname: user.enname, type: user.type, mobno: user.mobno, address: user.address, pass: user.pass, accno: user.accno, obal: user.obal, othev: user.othev, mhvah: user.mhvah, gayvah: user.gayvah, mhthev: user.mhthev, gaythev: user.gaythev, kapatbill: user.kapatbill, hptabill: user.hptabill, ikapatliter: user.ikapatliter, ikapatbill: user.ikapatbill, vibhag: user.vibhag, othev1: user.othev1, othev2: user.othev2, othev3: user.othev3 }),
                    setKno({ kno: kno })
                )
                )
            }
            setNewedit("edit");
        }).catch(err => {
            console.log(err);
        });
    }

    const handleReport = () => {
        setShowreport(true);
        getcustomers();
    }

    const closereport = () => {
        setShowreport(false);
    }


    async function getmaxno() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmaxno.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setKno({ kno: parseInt(response.data) + 1 });
                setInput(value => ({ ...value, knofrom: parseInt(response.data) + 1, knoto: parseInt(response.data) + 49 }

                ))

            }
            else {
                setKno({ kno: 1 })
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (showreport) {
            getcustomers();
        }
    }, [showreport])

    async function getcustomers() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getaccounttemp2.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                // console.log(response.data);
                response.data.map((user, index) =>
                (
                    //  setInput({ kno: parseInt(user.kno) + 1 })
                    setInput(value => ({ ...value, kno: parseInt(user.kno) + 1 }))
                )
                )
                setDataSource(response.data);

                localStorage.setItem("custlist", JSON.stringify(response.data));
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
            else {
                setInput({ kno: 1 })
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }

    const handledelete = (knodelete) => {

        refdelete.current.click();
        setKno({ kno: knodelete });
    }


    const handleblank = () => {

        refblank.current.click();

    }

    const handleblankclose = () => {
        refblankclose.current.click();
    }

    const deleteAccount = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            kno: kno.kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2
        }
        rawData = JSON.stringify(rawData);
        let formData = new FormData();
        formData.append('data', rawData);

        axios.post('https://idairy.co.in/dairy/php/cust/deletecust.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data.trim() === "Deleted") {
                notify("ग्राहक खाते डीलिट झाले!");
                refdeleteclose.current.click();
                getcustomers();
            }
            else {
                notifyerror("खाते क्रमांक- " + kno.kno + " ची माहिती उपलब्ध आहे, उपलब्ध माहिती- " + response.data);
                refdeleteclose.current.click();
                getcustomers();
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const uploadData = () => {



    };


    const uploadBlank = () => {
        refblankclose.current.click()
        let diff = 0;
        if (parseFloat(input.knofrom) < parseFloat(input.knoto)) {
            diff = parseFloat(input.knoto) - parseFloat(input.knofrom);
        }
        else {
            notifyerror("Invalid Series");
            return;
        }

        if (diff > 50) {
            notifyerror("Diffrance More Than 50");
            return;
        }


        const knoStart = parseInt(input.knofrom, 10);
        const knoEnd = parseInt(input.knoto, 10);

        if (isNaN(knoStart) || isNaN(knoEnd) || knoStart > knoEnd) {
            alert("Invalid range!");
            return;
        }

        let query = "INSERT INTO customer (kno, cname, enname, type, mobno, address, accno, mhthev, gaythev, ikapatliter, ikapatbill, mhvah, obal, othev, mainuser, pass, bankname, branch, aadhar, farmerid, vibhag, tag, accno2, milkonoff, msgonoff, mobileuser, ksr, othev1, othev2, othev3, class) VALUES ";

        const valuesArray = [];
        for (let kno = knoStart; kno <= knoEnd; kno++) {
            valuesArray.push(`('${kno}', '0', '0', 'म्हैस', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '${localStorage.getItem("contact")}', '0', '0', '0', '0', '0', 'दरपत्रक विभाग-१', '0', '0', 'चालू', 'चालू', '0', '0', '0', '0', '0', 'ब वर्ग')`);
        }

        query += valuesArray.join(", ") + ";";
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            query: query,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2
        }
        rawData = JSON.stringify(rawData);
        let formData = new FormData();
        formData.append('data', rawData);

        axios.post('https://idairy.co.in/dairy/php/cust/createblankcustomers.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            notify(response.data);
            getmaxno();


        }).catch(err => {
            console.log(err);
        });
    }

    const handlesubmit = async (event) => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        event.preventDefault();
        if (input.cname === "" || input.type === "प्रकार निवडा" || input.type === "" || input.mobno === "" || input.address === "" || input.accno === "" || input.obal === "" || input.othev === "" || input.bankname === "" || input.aadhar === "") {
            notifyerror("अपूर्ण माहिती!");
            return;
        }
        setWait(true);
        let rawData = {
            kno: kno.kno,
            sr: input.sr,
            cname: input.cname,
            enname: input.enname,
            type: input.type,
            mobno: input.mobno,
            address: input.address,
            accno: input.accno,
            farmerid: input.farmerid,
            obal: input.obal,
            othev: input.othev,
            othev1: input.othev1,
            othev2: input.othev2,
            othev3: input.othev3,
            oretanmedi: input.oretanmedi,
            bankname: input.bname,
            bank: input.bank,
            branch: input.branch,
            aadhar: input.aadhar,
            pass: input.pass,
            mhvah: input.mhvah,
            gayvah: input.gayvah,
            mhthev: input.mhthev,
            gaythev: input.gaythev,
            kapatbill: input.kapatbill,
            hptabill: input.hptabill,
            ikapatliter: input.ikapatliter,
            ikapatbill: input.ikapatbill,
            vibhag: input.vibhag,
            accno2: input.accno2,
            class: input.class,
            milkonoff: input.milkonoff,
            msgonoff: input.msgonoff,
            tag: input.tag,
            mobileuser: input.mobileuser,
            mainuser: localStorage.getItem("contact"),
            ksr: localStorage.getItem("ksr"),
            mainuser2: mainuser2,
            flag: newedit
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()

        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/createcustomervibhag2.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            // console.log(response.data);
            if (response.data === "Successfully saved.") {
                notify("सभासद खाते तयार झाले!");
                // setInput(prevInput => ({
                //     ...prevInput,
                //     sr: "", cname: "", type: "प्रकार निवडा", mobno: "", address: "", accno: "", pass: "Auto", obal: "", othev: "", farmerid: ""
                // }))
                setInput(prevInput => ({
                    ...prevInput,
                    sr: "", cname: "", enname: "0", type: "प्रकार निवडा", mobno: "", address: "", accno: "", pass: "Auto", obal: "", othev: "", othev1: "", othev2: "", othev3: "",oretanmedi:"", farmerid: "", aadhar: "", tag: "", accno2: "0", mobileuser: "", bname: "", branch: "", class: 'ब वर्ग'
                }))

                setKno(prevInput => ({
                    ...prevInput,
                    kno: (parseInt(prevInput.kno) + 1).toString()
                }))
                setNewedit("new");
                getcustomers();
                // clearAll();
            }
            else if (response.data === "Mobile Number Exists") {
                notifyerror("मोबाईल क्रमांक उपलब्ध आहे!");

            }
            else if (response.data === "Successfully Updated.") {
                notify("सभासद माहिती अपडेट झाली!");
                getcustomers();
            }
            else if (response.data === "Incomplete information!") {
                notifyerror("पूर्ण माहिती भरा!");
            }
            setWait(false);
        }).catch(err => {
            console.log(err);
        });
    }

    //                    kno, sr, cname,vibhag,type,mobno,address,pass,accno,obal,othev,mhvah,gayvah,mhthev,gaythev,kapatbill,hptabill,ikapatliter,ikapatbill,bankname,aadhar,farmerid 
    const handleedit = (kno, sr, cname, enname, vibhag, type, mobno, address, pass, accno, obal, othev, mhvah, gayvah, mhthev, gaythev, kapatbill, hptabill, ikapatliter, ikapatbill, bankname, aadhar, farmerid, tag, branch, accno2, milkonoff, msgonoff, mobileuser2, othev1, othev2, othev3, class2,oretanmedi) => {
        setNewcustsource("custreport");
        setInput(prevInput => ({
            ...prevInput,
            sr: sr, cname: cname, enname: enname, vibhag: vibhag, type: type, mobno: mobno, address: address, pass: pass, accno: accno, obal: obal, othev: othev, mhvah: mhvah, gayvah: gayvah, mhthev: mhthev, gaythev: gaythev, kapatbill: kapatbill, hptabill: hptabill, ikapatliter: ikapatliter, ikapatbill: ikapatbill, bname: bankname, aadhar: aadhar, farmerid: farmerid, tag: tag, branch: branch, accno2: accno2, milkonoff: milkonoff, msgonoff: msgonoff, mobileuser: mobileuser2, othev1: othev1, othev2: othev2, othev3: othev3, class: class2,oretanmedi:oretanmedi
        }))

        setKno({ kno: kno });
        setShowreport(false);
        setNewedit("edit");
    }

    useEffect(() => {
        console.log(input);
    }, [input])


    function getRandomNumber() {
        let min = 1000;
        let max = 9999;
        return Math.round(Math.random() * (max - min) + min);
    }

    const handleChangekno = (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        setKno(value => ({ ...value, [name]: valuecurrent }));
    }


    const handleChangeclass = (e) => {
        setClassName(e.target.value);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        if (name === "contact") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                valuecurrent = event.target.value;
                setInput(value => ({ ...value, [name]: valuecurrent }))

            }
        }
        else {
            if (name === "img") {
                valuecurrent = event.target.files[0].name;
            }
            else {
                valuecurrent = event.target.value;
            }
            setInput(value => ({ ...value, [name]: valuecurrent }))
        }
    }

    const filteData = (e) => {
        const searchValue = e.target.value.trim();

        if (searchValue !== "") {
            setValue(searchValue);
            const filtertable = DataSource.filter(o =>
                String(o.kno) === searchValue // Check for exact match with kno
            );

            setTableFilter([...filtertable]);
        } else {
            setValue(searchValue);
            setTableFilter([...DataSource]);
        }
    };

    const filteDataclass = (classname) => {
        const searchValue = classname;


        if (searchValue !== "") {
            setValue(searchValue);
            const filtertable = DataSource.filter(o =>
                String(o.class) === searchValue // Check for exact match with kno
            );

            setTableFilter([...filtertable]);
        } else {
            setValue(searchValue);
            setTableFilter([...DataSource]);
        }
    };


    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            handlesubmit();
            let nextIndex = null;
            nextIndex = currentIndex + 1;

            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[7]) {
                    inputRefs.current[7].focus();
                }
            }
        }
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refdelete}
                data-bs-target="#custdelete"
            >
            </button>

            <div className="modal fade" id="custdelete" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">ग्राहक खाते डीलिट करायचे?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={deleteAccount}>डीलिट</button>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refblank}
                data-bs-target="#blankupload"
            >
            </button>

            <div className="modal fade" id="blankupload" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">ब्लँक खाते अपलोडिंग</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refblankclose} aria-label="Close">
                            </button>

                        </div>

                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel"></h4>

                            <div className="rowcustmodal">
                                <div className="mb-2 se">
                                    <span className='subheadsank'>खाते क्र.</span>
                                    <input disabled maxLength={4} onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofromcust" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>खाते क्र</span>
                                    <input maxLength={4} onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knotocust" name="knoto" value={input.knoto} onChange={handleChange} ></input>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleblankclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={uploadBlank}>अपलोड</button>
                        </div>
                    </div>
                </div>
            </div>


            {
                showreport ?
                    <div className='backmaincust'>
                        <img onClick={handleBack} className='back' src={back} alt="" />
                        <div className="custbox">
                            <div className="buttonsreport">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {props.type} यादी
                                </h5>
                                <input type="text" className="form-control search mx-2" id="searchcust" name="search" placeholder='खाते क्रमांक शोधा' value={value} onChange={filteData} autoComplete='off' />

                                <div className='classv' >
                                    <label>
                                        <div className="classbox">
                                            {["अ वर्ग", "ब वर्ग", "क वर्ग"].map((classname) => (

                                                <label key={classname}>
                                                    <div className='classbox'>
                                                        <input
                                                            type="radio"
                                                            name="class"
                                                            value={classname}
                                                            checked={className === classname}
                                                            onChange={(e) => {
                                                                setClassName(e.target.value); // Update state
                                                                filteDataclass(e.target.value); // Call filtering function
                                                            }}
                                                        />

                                                        <span className='classspan'>{classname}</span>
                                                        <div className='vlineclass'></div>
                                                    </div>

                                                </label>
                                            ))}
                                        </div>
                                    </label>
                                </div>

                                <button onClick={showprint} className="download-table-xls-button btn btn-primary mb-3 my-3" type="submit">प्रिंट यादी</button>
                            </div>
                            <div className="table-containercust">
                                <table id="myTable" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">खाते नंबर</th>
                                            <th scope="col">नाव</th>
                                            <th scope="col">सुरू.बाकी</th>
                                            <th scope="col">सुरू.ठेव</th>
                                            <th scope="col">पासवर्ड</th>
                                            <th scope="col">वर्ग</th>
                                            <th scope="col">एक्शन</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {value.length > 0 ? tableFilter.map((user, index) =>
                                            <tr key={index}>
                                                <td className='thsmall2cust'>{user.kno}</td>
                                                <td className='thsmall2cust'>{user.cname + " (" + user.type + ")"}</td>
                                                <td className='thsmall2cust'>{user.obal}</td>
                                                <td className='thsmall2cust'>{user.othev}</td>
                                                <td className='thsmall2cust'>{user.pass}</td>
                                                <td className='thsmall2cust'>{user.class}</td>
                                                <td className='thsmall2cust'><UilEditAlt onClick={() => { handleedit(user.kno, user.sr, user.cname, user.enname, user.vibhag, user.type, user.mobno, user.address, user.pass, user.accno, user.obal, user.othev, user.mhvah, user.gayvah, user.mhthev, user.gaythev, user.kapatbill, user.hptabill, user.ikapatliter, user.ikapatbill, user.bankname, user.aadhar, user.farmerid, user.tag, user.branch, user.accno2, user.milkonoff, user.msgonoff, user.mobileuser, user.othev1, user.othev2, user.othev3, user.class) }} className="ic" /><UilTrash onClick={() => { handledelete(user.kno) }} v className="ic" /></td>
                                            </tr>
                                        )
                                            :
                                            DataSource.map((user, index) =>
                                                <tr key={index}>
                                                    <td className='thsmall2cust'>{user.kno}</td>
                                                    <td className='thsmall2cust'>{user.cname + " (" + user.type + ")"}</td>
                                                    <td className='thsmall2cust'>{user.obal}</td>
                                                    <td className='thsmall2cust'>{user.othev}</td>
                                                    <td className='thsmall2cust'>{user.pass}</td>
                                                    <td className='thsmall2cust'>{user.class}</td>
                                                    <td className='thsmall2cust'><UilEditAlt onClick={() => { handleedit(user.kno, user.sr, user.cname, user.enname, user.vibhag, user.type, user.mobno, user.address, user.pass, user.accno, user.obal, user.othev, user.mhvah, user.gayvah, user.mhthev, user.gaythev, user.kapatbill, user.hptabill, user.ikapatliter, user.ikapatbill, user.bankname, user.aadhar, user.farmerid, user.tag, user.branch, user.accno2, user.milkonoff, user.msgonoff, user.mobileuser, user.othev1, user.othev2, user.othev3, user.class,user.oretanmedi) }} className="ic" /><UilTrash onClick={() => { handledelete(user.kno) }} className="ic" /></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    :
                    <div className='backmaincust'>
                        <img onClick={handleBack} className='back' src={back} alt="" />
                        <div className="custbox">
                            <form>
                                {page === "first" ?
                                    <div className="firstpage">
                                        <div className="row1formcust">
                                            <div className="mb-2">
                                                <span className='subheadsank'> खाते क्रमांक </span>
                                                <input onChange={handleChangekno} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="number" disabled={newedit === "new" ? 'true' : 'false'} className={newedit === "new" ? "form-control" : "form-control editmode"} id="kno" name="kno" value={kno.kno} autoComplete='off' />
                                            </div>
                                            <div className="mb-2 lastimg">
                                                <UilSetting className='settingcust' onClick={handlepage} />
                                            </div>
                                        </div>
                                        <div className="row1">
                                            <div className="mb-2">
                                                <span className='subheadsank'> सभासदाचे नाव</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="cname" name="cname" value={input.cname} autoComplete='off' />
                                            </div>
                                            <div className="mb-2">
                                                <span className='subheadsank'> दूध प्रकार</span>
                                                <div className="xx">
                                                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} value={input.type} name='type' id='typecust' onChange={handleChange} className={newedit === "new" ? "form-control" : "form-control editmode"} >
                                                        <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                                                        <option key={1} value="म्हैस">म्हैस</option>
                                                        <option key={2} value="गाय">गाय</option>
                                                        <option key={3} value="दोन्ही">दोन्ही</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-2">
                                                <span className='subheadsank'> मोबाईल क्रमांक</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} id="mobno" name="mobno" value={input.mobno} autoComplete='off' />
                                            </div>
                                        </div>

                                        <div className="row1">
                                            <div className="mb-2">
                                                <span className='subheadsank'> पत्ता</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="address" name="address" value={input.address} autoComplete='off' />
                                            </div>

                                            <div className="mb-2">
                                                <span className='subheadsank'>ऍप पासवर्ड</span>
                                                <input onChange={handleChange} disabled={newedit === 'new' ? false : false} onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="pass" name="pass" value={input.pass} autoComplete='off' />
                                            </div>

                                            <div className="mb-2">
                                                <span className='subheadsank'>मोबाईल यूजर नाव</span>
                                                <select onKeyDown={e => handleEnterKey(e, 6)} ref={ref => (inputRefs.current[6] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="mobileuser" name="mobileuser" value={input.mobileuser} onChange={handleChange}>
                                                    <option key={0} value={"मोबाईल यूजर नाव निवडा"}>मोबाईल यूजर नाव निवडा</option>
                                                    {mobileusers ? mobileusers.map((opt2, index) =>

                                                        <option key={index + 1} value={opt2.name}>{opt2.name}</option>

                                                    ) : ""}
                                                </select>
                                            </div>

                                        </div>

                                        <div className="row1">
                                            <div className="mb-2">
                                                <span className='subheadsank'>बँक नाव</span>
                                                <select onKeyDown={e => handleEnterKey(e, 7)} ref={ref => (inputRefs.current[7] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="bname" name="bname" value={input.bname} onChange={handleChange}>
                                                    <option key={0} value={"बँकेचे नाव निवडा"}>बँकेचे नाव निवडा</option>
                                                    {bankname ? bankname.map((opt, index) =>

                                                        <option key={index + 1} value={opt.bname}>{opt.bname}</option>

                                                    ) : ""}
                                                </select>
                                            </div>
                                            <div className="mb-2">
                                                <span className='subheadsank'>बँक शाखा</span>
                                                <select onKeyDown={e => handleEnterKey(e, 8)} ref={ref => (inputRefs.current[8] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="branch" name="branch" value={input.branch} onChange={handleChange}>
                                                    <option key={0} value={"बँक शाखा निवडा"}>बँक शाखा निवडा</option>
                                                    {bankname
                                                        ? bankname
                                                            .filter(opt => opt.bname === input.bname)  // Filter the branches based on the selected bank
                                                            .map((opt, index) => (
                                                                <option key={index + 1} value={opt.branch}>{opt.branch}</option>
                                                            ))
                                                        : ""}
                                                </select>
                                            </div>
                                        </div>


                                        <div className="row1">
                                            <div className="mb-2">
                                                <span className='subheadsank'> बँक खाते क्रमांक</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 9)} ref={ref => (inputRefs.current[9] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="accno" name="accno" value={input.accno} autoComplete='off' />
                                            </div>


                                            <div className="mb-2">
                                                <span className='subheadsank'>आधार क्रमांक</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 10)} ref={ref => (inputRefs.current[10] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} id="aadhar" name="aadhar" value={input.aadhar} autoComplete='off' />
                                            </div>

                                            <div className="mb-2">
                                                <span className='subheadsank'>फार्मर आयडी</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 11)} ref={ref => (inputRefs.current[11] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="farmerid" name="farmerid" value={input.farmerid} autoComplete='off' />
                                            </div>
                                        </div>

                                        <div className="row1">

                                            <div className="mb-2">
                                                <span className='subheadsank'> टॅग नंबर</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 12)} ref={ref => (inputRefs.current[12] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="tag" name="tag" value={input.tag} autoComplete='off' />
                                            </div>
                                        </div>

                                        <div className="row1">
                                            <div className="mb-2">
                                                <span className='subheadsank'>दरपत्रक विभाग</span>
                                                <select onKeyDown={e => handleEnterKey(e, 13)} ref={ref => (inputRefs.current[13] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="vibhag" name="vibhag" value={input.vibhag} onChange={handleChange}>
                                                    <option key={0} value="दरपत्रक विभाग-१">दरपत्रक विभाग-१</option>
                                                    <option key={1} value="दरपत्रक विभाग-२">दरपत्रक विभाग-२</option>
                                                    <option key={2} value="दरपत्रक विभाग-३">दरपत्रक विभाग-३</option>
                                                    <option key={3} value="दरपत्रक विभाग-४">दरपत्रक विभाग-४</option>
                                                    <option key={4} value="दरपत्रक विभाग-५">दरपत्रक विभाग-५</option>
                                                    <option key={5} value="दरपत्रक विभाग-६">दरपत्रक विभाग-६</option>
                                                    <option key={6} value="दरपत्रक विभाग-७">दरपत्रक विभाग-७</option>
                                                    <option key={7} value="दरपत्रक विभाग-८">दरपत्रक विभाग-८</option>
                                                    <option key={8} value="दरपत्रक विभाग-९ (M)">दरपत्रक विभाग-९ (M)</option>
                                                </select>
                                            </div>

                                            <div className="mb-2">
                                                <span className='subheadsank'> मागील येणेबाकी</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 14)} ref={ref => (inputRefs.current[14] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} id="obal" name="obal" value={input.obal} autoComplete='off' />
                                            </div>
                                            <div className="mb-2">
                                                <span className='subheadsank'> मागील ठेव</span>
                                                <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 15)} ref={ref => (inputRefs.current[15] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} id="othev" name="othev" value={input.othev} autoComplete='off' />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="secondpage">
                                        <div className="firstrowcust">
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>म्हैस ठेव/लिटर</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="mhthev"
                                                        name="mhthev"
                                                        value={input.mhthev}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div>
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>एड्व्हांस हप्ता/बिल</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="kapatbill"
                                                        name="kapatbill"
                                                        value={input.kapatbill}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>ई.कपात/लिटर</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="ikapatliter"
                                                        name="ikapatliter"
                                                        value={input.ikapatliter}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div>
                                            <div className="mb-2 mx-2 bbb">
                                                <span className='subhead'>म्हैस वाहतूक/लिटर</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="mhvah"
                                                        name="mhvah"
                                                        value={input.mhvah}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="firstrowcust">
                                            {/* <div className="bbbs">
                                                <span className='subhead'>गाय वाहतूक/लिटर</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="gayvah"
                                                        name="gayvah"
                                                        value={input.gayvah}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div> */}
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>गाय ठेव/लिटर</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="gaythev"
                                                        name="gaythev"
                                                        value={input.gaythev}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div>
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>--</span>
                                                {/* खाद्य हप्ता/बिल */}
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="hptabill"
                                                        name="hptabill"
                                                        value={input.hptabill}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div>
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>ई.कपात/बिल</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="ikapatbill"
                                                        name="ikapatbill"
                                                        value={input.ikapatbill}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />

                                                </div>
                                            </div>

                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>गाय वाहतूक/लिटर</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="gayvah"
                                                        name="gayvah"
                                                        value={input.gayvah}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="firstrowcust">
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>बँक खाते क्रमांक 2</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="accno2"
                                                        name="accno2"
                                                        value={input.accno2}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step="0.1"
                                                        autoComplete='off' />
                                                </div>
                                            </div>

                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>सभासद नाव (इंग्लिश)</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="enname"
                                                        name="enname"
                                                        value={input.enname}
                                                        onChange={handleChange}
                                                        autoComplete='off' />
                                                </div>
                                            </div>

                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>दूध चालू/बंद</span>
                                                <div className="selection">
                                                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} value={input.milkonoff} name='milkonoff' id='milkonoff' onChange={handleChange} className={newedit === "new" ? "form-control" : "form-control editmode"} >
                                                        <option key={0} value="चालू">चालू</option>
                                                        <option key={1} value="बंद">बंद</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>मेसेज चालू/बंद</span>
                                                <div className="selection">
                                                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} value={input.msgonoff} name='msgonoff' id='msgonoff' onChange={handleChange} className={newedit === "new" ? "form-control" : "form-control editmode"} >
                                                        <option key={0} value="चालू">चालू</option>
                                                        <option key={1} value="बंद">बंद</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="firstrowcust">
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>मागील ठेव १</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="othev1"
                                                        name="othev1"
                                                        value={input.othev1}
                                                        onChange={handleChange}
                                                        type="number"
                                                        autoComplete='off' />
                                                </div>
                                            </div>

                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>मागील ठेव २</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="othev2"
                                                        name="othev2"
                                                        value={input.othev2}
                                                        onChange={handleChange}
                                                        type="number"
                                                        autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>मागील ठेव ३</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="othev3"
                                                        name="othev3"
                                                        value={input.othev3}
                                                        onChange={handleChange}
                                                        type="number"
                                                        autoComplete='off' />
                                                </div>
                                            </div>

                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>मागील रेतन/पशुवैद्यकीय</span>
                                                <div className="selection">
                                                    <input className={newedit === "new" ? "form-control centered-input" : "form-control centered-input editmode"}
                                                        id="oretanmedi"
                                                        name="oretanmedi"
                                                        value={input.oretanmedi}
                                                        onChange={handleChange}
                                                        type="number"
                                                        autoComplete='off' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="firstrowcust">
                                            <div className="mb-2 mx-2 bbsank">
                                                <span className='subhead'>वर्ग</span>
                                                <div className="selection">
                                                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} value={input.class} name='class' id='class' onChange={handleChange} className={newedit === "new" ? "form-control" : "form-control editmode"} >
                                                        <option key={0} value="ब वर्ग">ब वर्ग</option>
                                                        <option key={1} value="अ वर्ग">अ वर्ग</option>
                                                        <option key={1} value="क वर्ग">क वर्ग</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='row1formcust'>
                                    {
                                        wait ?
                                            <button onClick={handlesubmit} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[16] = ref)} type="submit" className="btn btn-primary my-3 mx-1">
                                                लोडिंग..
                                            </button>
                                            :
                                            <button onClick={handlesubmit} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[16] = ref)} type="submit" className="btn btn-primary my-3 mx-1">
                                                {newedit === "new" ?
                                                    "सेव्ह" :
                                                    "अपडेट"
                                                }
                                            </button>
                                    }

                                    <button type='button' onClick={handleReport} className="btn btn-primary my-3 mx-1">सभासद यादी</button>

                                    <button type='button' onClick={handleblank} className="btn btn-success my-3 mx-1">ब्लँक अपलोड</button>
                                </div>
                            </form>
                        </div>
                    </div>
            }
            <ToastContainer />
        </>
    );
};

export default NewCustomer;

import React, { useContext, useEffect, useRef, useState } from 'react'
import './Profitloss.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Language, YoutubeSearchedForOutlined } from '@material-ui/icons';
import khadya from "../../imgs/khadya.png";
import cash from "../../imgs/cash.png";
import { UilEditAlt, UilEye, UilSetting } from '@iconscout/react-unicons'


const Profitloss = (props) => {
    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), kno: "", kname: "", lastkhadya: "", lastadv: "", lastdate: "", dno: "", name: "", khname: "", searchsank: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [DataSourcekh, setDataSourcekh] = useState([]);
    const [DataSourceadv, setDataSourceadv] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const [partyData, setPartydata] = useState([]);
    const [PreviousAmt, setPreviousAmt] = useState();
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const [khrtype, setKhrtype] = useState("khpur");
    const navigate = useNavigate();
    const refprint = useRef(null);
    const [khnames, setKhnames] = useState([]);
    const refprintclose = useRef(null);
    const [mobileusers, setMobileusers] = useState([]);
    const [wait, setWait] = useState(false);

    const handlertype = (type) => {
        setKhrtype(type)
    }

    useEffect(() => {
        setDataSource([]);
    }, [khrtype])

    const handleprintclose = () => {
        refprintclose.current.click();
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }


    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);

            const url = "/profitlossprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीची माहिती उपलब्ध नाही!");
        }
    }


    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date2') {
            // Check if date2 is more than one year ahead of date1
            const date1 = new Date(input.date1);
            const date2 = new Date(valuecurrent);

            // Calculate the difference in days between date1 and date2
            const differenceInDays = (date2 - date1) / (1000 * 60 * 60 * 24);

            if (differenceInDays <= 30) {
                setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
            } else {
                notifyerror("रिपोर्ट लिमिट - 1 महिना");
                setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
            }
        }
    }

    const getprofitloss = () => {
        setValue("");
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";
        // console.log(khrtype);

        landingphp = "getprofitandloss4.php";
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        console.log(rawData);
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data)
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);

                // if (khrtype === "dreport") {

                //     setPreviousAmt(response.data[0].
                //         previousamt
                //     );
                // }

                setWait(false);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }



    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };


    function groupByHead(data, isPositive = true) {
        return data.reduce((acc, row) => {
            const head = isPositive ? row.positive_head : row.negative_head;
            if (!head) return acc;
            if (!acc[head]) {
                acc[head] = { total: 0, records: [] };
            }
            const amt = isPositive
                ? parseFloat(row.positive_amount || 0)
                : parseFloat(row.negative_amount || 0);
            acc[head].total += amt;
            acc[head].records.push(row);
            return acc;
        }, {});
    }

    function toGroupArray(groupedObj) {
        return Object.entries(groupedObj).map(([head, info]) => ({
            head,
            total: info.total,
            records: info.records,
        }));
    }

    const sourceData = DataSource;
    const posDict = groupByHead(sourceData, true);
    const negDict = groupByHead(sourceData, false);
    const posGroups = toGroupArray(posDict);
    const negGroups = toGroupArray(negDict);

    // **Find total number of rows in each side**
    const posRows = posGroups.reduce((sum, group) => sum + group.records.length + 1, 0); // +1 for group header
    const negRows = negGroups.reduce((sum, group) => sum + group.records.length + 1, 0); // +1 for group header

    const maxRows = Math.max(posRows, negRows);

    // **Function to add blank rows**
    function addBlankRows(groups, totalRows) {
        let currentRows = groups.reduce((sum, group) => sum + group.records.length + 1, 0);
        let blanksNeeded = totalRows - currentRows;

        if (blanksNeeded > 0) {
            groups.push({
                head: "",
                total: 0,
                records: new Array(blanksNeeded).fill({ positive_kno: "", positive_name: "", positive_amount: "" }),
            });
        }
        return groups;
    }

    // **Equalize number of rows**
    const posGroupsFilled = addBlankRows([...posGroups], maxRows);
    const negGroupsFilled = addBlankRows([...negGroups], maxRows);

    const grandPosTotal = sourceData.reduce(
        (sum, row) => sum + parseFloat(row.positive_amount || 0),
        0
    );
    const grandNegTotal = sourceData.reduce(
        (sum, row) => sum + parseFloat(row.negative_amount || 0),
        0
    );




    // 3) Determine the maximum number of groups so we can iterate side by side
    const maxGroups = Math.max(posGroups.length, negGroups.length);

    useEffect(() => {
        if (input.dno === "") {
            setInput(prevInput => ({
                ...prevInput,
                name: "",
            }))
        }
        else {

            let custlisttemp = JSON.parse(localStorage.getItem("auditlist"));
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.dno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.name,
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.dno])

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmain">
                        <div className="firstrow2">
                            <div className="rowbill">
                                <>
                                    <div className="mb-2">
                                        <span className='subheadsank'>दिनांक</span>
                                        <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                    </div>

                                    <span className='subheadbill'>ते</span>

                                    <div className="mb-2">
                                        <span className='subheadsank'>दिनांक</span>
                                        <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                    </div>
                                </>

                                <div className="mb-2">
                                    {
                                        wait ?
                                            <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">लोडिंग..</button>
                                            :
                                            <button onClick={getprofitloss} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>

                                    }
                                    <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="firstrow2">
                    <div class="table-containerbill">

                        <div style={{ display: 'flex' }}>
                                                                           {/* Positive Side Table */}
                                                                           <div style={{ width: '50%', paddingRight: '0%' }}>
                                                                               <table id="myTablebill" className="table table-bordered">
                                                                                   <thead>
                                                                                       <tr>
                                                                                           <th className="thlarge">नावे बाजू</th>
                                                                                           <th className="thsmall">पोटरक्कम</th>
                                                                                           <th className="thsmall">रक्कम</th>
                                                                                       </tr>
                                                                                   </thead>
                                                                                   <tbody>
                                                                                       {posGroupsFilled.map((group, gIndex) => (
                                                                                           <React.Fragment key={`pos-group-${gIndex}`}>
                                                                                               {group.head && (
                                                                                                   <tr style={{ backgroundColor: "#eee", fontWeight: "bold" }}>
                                                                                                       <td className="thsmallnavelefttale" colSpan="2">{group.head}</td>
                                                                                                       <td className="thsmallright"> {group.total.toFixed(2)}</td>
                                                                                                   </tr>
                                                                                               )}
                                                                                               {group.records.map((rec, rIndex) => (
                                                                                                   <tr key={`pos-group-${gIndex}-row-${rIndex}`}>
                                                                                                       <td className="thsmallcust">{rec.positive_kno ? `${rec.positive_kno} : ${rec.positive_name}` : ""}</td>
                                                                                                       <td className="thsmallcust">{rec.positive_amount ? parseFloat(rec.positive_amount).toFixed(2) : ""}</td>
                                                                                                       <td className="thsmallcust">-</td>
                                                                                                   </tr>
                                                                                               ))}
                                                                                           </React.Fragment>
                                                                                       ))}
                                                                                   </tbody>
                                                                                   <tfoot>
                                                                                       <tr style={{ backgroundColor: "lightgray", fontWeight: "bold" }}>
                                                                                           <td className="thlargegreen2">-</td>
                                                                                           <td className="thsmallgreen2">-</td>
                                                                                           <td className="thsmallgreen2">{grandPosTotal.toFixed(2)}</td>
                                                                                       </tr>
                                                                                   </tfoot>
                                                                               </table>
                                                                           </div>
                                               
                                                                           {/* Negative Side Table */}
                                                                           <div style={{ width: '50%', paddingLeft: '0%' }}>
                                                                               <table id="myTablebill" className="table table-bordered">
                                                                                   <thead>
                                                                                       <tr>
                                                                                           <th className="thlarge">जमा बाजू</th>
                                                                                           <th className="thsmall">पोटरक्कम</th>
                                                                                           <th className="thsmall">रक्कम</th>
                                                                                       </tr>
                                                                                   </thead>
                                                                                   <tbody>
                                                                                       {negGroupsFilled.map((group, gIndex) => (
                                                                                           <React.Fragment key={`neg-group-${gIndex}`}>
                                                                                               {group.head && (
                                                                                                   <tr style={{ backgroundColor: "#eee", fontWeight: "bold" }}>
                                                                                                       <td className="thsmallnavelefttale" colSpan="2">{group.head}</td>
                                                                                                       <td className="thsmallright">{group.total.toFixed(2)}</td>
                                                                                                   </tr>
                                                                                               )}
                                                                                               {group.records.map((rec, rIndex) => (
                                                                                                   <tr key={`neg-group-${gIndex}-row-${rIndex}`}>
                                                                                                       <td className="thsmallcust">{rec.negative_kno ? `${rec.negative_kno} : ${rec.negative_name}` : ""}</td>
                                                                                                       <td className="thsmallcust">{rec.negative_amount ? parseFloat(rec.negative_amount).toFixed(2) : ""}</td>
                                                                                                       <td className="thsmallcust">-</td>
                                                                                                   </tr>
                                                                                               ))}
                                                                                           </React.Fragment>
                                                                                       ))}
                                                                                   </tbody>
                                                                                   <tfoot>
                                                                                       <tr style={{ backgroundColor: "lightgray", fontWeight: "bold" }}>
                                                                                           <td className="thlargegreen2">-</td>
                                                                                           <td className="thsmallgreen2">-</td>
                                                                                           <td className="thsmallgreen2">{grandNegTotal.toFixed(2)}</td>
                                                                                       </tr>
                                                                                   </tfoot>
                                                                               </table>
                                                                           </div>
                                                                       </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Profitloss

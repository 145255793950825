import React, { useContext, useEffect, useRef, useState } from 'react'
import './Dashcontents.css'
import farmer from "../../imgs/farmer.png";
import sanghicon from "../../imgs/sangh.png";
import ratib from "../../imgs/ratib.webp";
import newrates from "../../imgs/newrates.png";
import axios from 'axios';
import center from "../../imgs/center.png";
import qr from "../../imgs/QR.png";
import khadya from "../../imgs/khadya.png";
import bank from "../../imgs/bank.png";
import ratetable from "../../imgs/ratetable.png";
import settings from "../../imgs/settings.png";
import Box from '../Box/Box';
import Cowleft from "../../imgs/cowleft.gif";
import Newcustomer from '../Newcustomer/Newcustomer';
import Sangh from '../Sangh/Sangh';
import Newkendra from '../Newkendra/Newkendra';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import Ratetable from '../Ratetable/Ratetable';
import Setting from '../Settings/Settings';
import Nrepair from '../Nrepair/Nrepair';
import Globalcontext from '../../Context/Globalcontext';
import { ToastContainer, toast } from 'react-toastify';
import Ratibmaster from '../Ratibmaster/Ratibmaster';
import Bank from '../Bank/Bank';
import Khparty from '../Khparty/Khparty';
import Kapatmaster from '../Kapatmaster/Kapatmaster';
import Ratetablesangh from '../Ratetablesangh/Ratetablesangh';

const Dashcontendts = (props) => {

    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const [expirebox, setExpirebox] = useState(false);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const notify = (msg) => toast(msg);
    const refnewkendra = useRef(null);
    const [daysRemaining, setDaysRemaining] = useState(0);
    const refnewkendraclose = useRef(null);
    const refprint = useRef(null);
    const refprintclose = useRef(null);

    const navigate = useNavigate();
    const { newcustsource, setNewcustsource, kendrasource, setKendrasource, setHeadname, custlist, setCustlist } = useContext(Globalcontext);
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    useEffect(() => {


        if (newcustsource === "sank") {
            setShowBackImage(true);
            setMastermenu(false);
            setTransitionEnded(true);
            if (submenu === "customer") {
                setkopen(0);
            }
        }
        //getmyinfo();

        if (localStorage.getItem('contact') === "9975337297") {

            const currentDate = new Date();

            // Target date
            const targetDate = new Date(localStorage.getItem("vdate"));

            // Calculate the difference in milliseconds
            const differenceInTime = targetDate.getTime() - currentDate.getTime();

            // Convert the difference from milliseconds to days
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

            // Set the result in state
            setDaysRemaining(differenceInDays);


            if (differenceInDays <= 7) {
                handlePrint();
                setExpirebox(true);
            }
        }
    }, [])


    const areUSure = (choose) => {
        handleDialog("", false);
    };

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const showdialog = () => {
        setkopen(1);
        handleDialog("केंद्र उपलब्ध नाही?", true, `नवीन केंद्र तयार करायचे?`);
    }
    const openkendra = () => {
        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");
        setkopen(1);
    }

    useEffect(() => {

        if (newcustsource === "sank") {
            setShowBackImage(true);
            setMastermenu(false);
            setTransitionEnded(true);
            if (submenu === "customer") {
                setkopen(0);
            }
        }
        if (kendrasource === "Dashboard" || kendrasource === "Transection") {
            setShowBackImage(true);
            setMastermenu(false);
            setTransitionEnded(true);
            if (submenu === "kendra") {
                setkopen(1);
            }
        }

        if (localStorage.getItem("contact") === "" || localStorage.getItem("contact") === null) {
            navigate("/");
            localStorage.setItem("status", "");
            localStorage.setItem("contact", "");
        }
        // console.log("kendra name- ",localStorage.getItem("kendra"));
        if (localStorage.getItem("kendra") === "Not available" && kopen === 0) {
            showdialog();
        }
    })

    const visibility = () => {

        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setHeadname("मास्टर माहिती");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };

    const handlePrint = () => {
        refprint.current.click();
    }

    function formatDate(inputDate) {
        // Convert the input date string to a Date object
        const date = new Date(inputDate);

        // Define month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr',
            'May', 'Jun', 'Jul', 'Aug',
            'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get the day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Format the date as 'day Month Year'
        const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

        return formattedDate;
    }



    const handleprintclose = () => {
        refprintclose.current.click();
    }

    const logout = (contact) => {
        refnewkendraclose.current.click();
        localStorage.setItem("status", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("contactplant", "");
        localStorage.setItem("kendra", "Not available");
        localStorage.setItem("kmobno", "");
        localStorage.setItem("ksr", "");
        localStorage.setItem("status", "");
        localStorage.setItem("vdate", "");


        props.setProgress(50);
        setTimeout(() => {
            props.setProgress(100);
        }, 200);

        navigate("/");
        notify("Logged Out.");
    }

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">आपल्या सॉफ्टवेअरची व्हॅलिडिटी काही दिवसात समाप्त होत आहे.</h5>
                            <span className='note'>सूचना : कृपया आपली वार्षिक रक्कम भरून घ्यावी, अन्यथा आपले सॉफ्टवेअर बंद होऊ शकते!</span>
                        </div>

                        <div className="expiry">

                            <span className='noteblue'>१. समाप्त दिनांक: {formatDate(localStorage.getItem("vdate")) + ", शिल्लक दिवस: " + daysRemaining}</span>
                            <span className='noteblue'>२. वार्षिक रक्कम ही आपल्या सॉफ्टवेअर पॅकेज नुसार असेल याची नोंद घ्यावी.(रू.१००० ते रु.५०००)</span>
                            <span className='noteblue'>३. खाली दिलेला QR स्कॅन करुन आपण आपले पेमेंट करू शकता.</span>
                            <span className='noteblue'>4. संपर्क क्रमांक: (91) 800-788-9200</span>

                            <span className='noteblack'>Scan QR Code Below</span>

                            <img className='qrimg' src={qr} alt="" />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={handleprintclose}>ओके</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className='line' />
            <div className="maincon">

                <span className={`heading1 ${mastermenu ? '' : 'hidden'}`}>मास्टर माहिती</span>
                <div className={`Dashcontents ${mastermenu ? '' : 'hidden'}`} onTransitionEnd={handleTransitionEnd}>
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t1), var(--t1))"} head={"सभासद मस्टर"} img={<img src={farmer} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t5dark), var(--t5dark))"} head={"संघ मस्टर"} img={<img src={sanghicon} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t6dark), var(--t6dark))"} head={"रतीब ग्राहक"} img={<img src={ratib} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t2dark), var(--t2dark))"} head={"खाद्य डिलर"} img={<img src={khadya} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t1), var(--t1))"} head={"दरपत्रक (संघ)"} img={<img src={ratetable} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3), var(--t3))"} head={"दरपत्रक (केंद्र)"} img={<img src={ratetable} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t4), var(--t4))"} head={"वाहतूक/कपात सेटिंग"} img={<img src={settings} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3), var(--t3))"} head={"बँक मस्टर"} img={<img src={bank} alt="" />} />

                </div>



                <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
                    <div className="first">

                    </div>
                    <div className="second">
                        <div className='line' />
                        <span className='firstspan'>1. येथे आपण सोफ्टवेअर मध्ये लागणारी सर्व नवीन खाती तयार करू शकता.</span>
                        <span className='infospan'>2. सोफ्टवेअर मध्ये कोणतीही एन्ट्री करण्याअगोदर त्याचे खाते तयार करणे आवश्यक असते.</span>
                        <span className='infospan'>3. येथे आपण नवीन सभासद,नवीन दरपत्रक व इतर सेटिंग करू शकता.</span>
                    </div>

                </div>


                <div className={`cust ${mastermenu ? 'hidden' : ''}`}>
                    {transitionEnded && showBackImage && (
                        submenu === "customer" ? <Newcustomer setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                            submenu === "sangh" ? <Sangh setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                submenu === "ratibmaster" ? <Ratibmaster setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                    submenu === "khparty" ? <Khparty setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                        submenu === "kendra" ? <Newkendra setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                            submenu === "ratetable" ? <Ratetable setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                submenu === "ratetablesangh" ? <Ratetablesangh setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                    submenu === "settings" ? <Setting setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                        submenu === "kapatmaster" ? <Kapatmaster setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                            submenu === "bank" ? <Bank setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                submenu === "nrepair" ? <Nrepair setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                    ""
                    )}
                </div>

            </div>
            <div className="secrow">
                <div className="leftsecrow">

                </div>
                {
                    submenu !== "ratetable" && submenu !== "ratetablesangh" && submenu !== "sangh" && submenu !== "ratibmaster" && submenu !== "khparty" && submenu !== "bank" && submenu !== "kapatmaster"  && submenu !== "customer" ?
                        <div className="imgcon">
                            <img className='imgcowdash' src={Cowleft} alt="" />
                        </div>
                        : ""
                }

            </div>

            {dialog.isLoading && (
                <Dialog
                    //Update
                    submessage={dialog.nameProduct}
                    onDialog={areUSure}
                    message={dialog.message}
                    functionname={openkendra}
                    button1text="नाही"
                    button2text="होय"
                />
            )}
        </>
    )
}

export default Dashcontendts
